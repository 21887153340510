import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function XorazmMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[0][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/bogot-tumani">
                                        <path d="M387.21,294.58s-3.21,9.92-5.88,11.25-9.66,0-9.66,0l3.66,10s-7.5,19-13.83,26-10.17,22.32-10.17,32c-17.59.66-43.17,1.59-63.58,2.17-13.18.38-26.23,1.21-37.74,2.07l-.08-1.24c-.08-1.24,3.4-8.33,3.4-10.83s-2.83-.83-3.33-3.5,1.67-6,3.83-8.33A39,39,0,0,0,261,341.5c2-5.67,5.83-14.83,10.67-18,2.14-1.4,7-2,11.67-2.27,5.92-.32,11.66-.06,11.66-.06a57,57,0,0,1,7.83-6c3.67-2.17,10.5-1.17,23.34-2.5S343.83,306,343.83,306l1.67-6.67-3.5-7,7-2s18,.67,20.27,0,12-13.22,12-13.22a6.23,6.23,0,0,1,.75,1.64,71,71,0,0,1,1.5,9.75Z" data-name="Боғот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[7][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/xonqa-tumani">
                                        <path d="M381.25,277.11s-9.71,12.56-12,13.22-20.27,0-20.27,0l-7,2,3.5,7L343.83,306s-4.83,5.33-17.66,6.67-19.67.33-23.34,2.5a57,57,0,0,0-7.83,6s-5.74-.26-11.66.06l1.66-8.06.83-11.67c-6.16-5.5-20.33-5.33-27-6.92s-7.5-5.58-7.5-5.58l4.17-6.33L249.93,267s8.57-.17,15.9,0,18.5,3.17,21,1.5,3.67-9.67,3.67-9.67-7-5.83-6.5-8S314,249,314,249l7.74-15.42c3.34,3.53,7.66,8.41,8.26,10.67a18.94,18.94,0,0,1,0,8s15.25,6,18,7.75,14.25,13.5,14.25,13.5h6l5.5-3.75S379.11,273.63,381.25,277.11Z" data-name="Ҳонқа тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[9][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/yangiariq-tumani">
                                        <path d="M285.83,301.5,285,313.17l-1.66,8.06c-4.71.25-9.53.87-11.67,2.27-4.84,3.17-8.67,12.33-10.67,18a39,39,0,0,1-7.17,12.67c-2.16,2.33-4.33,5.66-3.83,8.33s3.33,1,3.33,3.5-3.48,9.59-3.4,10.83l.08,1.24c-19,1.42-33.9,2.9-38.26,2.43-4.7-.5-14-5.18-21.84-10.09l-.58-7.91s4.17-14.5,4.5-17.67,3.34-8.66,3.34-8.66l4.5,1.5v4h3.83l1.33-5.5s-2.33-1.34-.83-10.84,6-8.5,8.33-10.5S222.67,301,222.67,301l24-12h4.66s.84,4,7.5,5.58S279.67,296,285.83,301.5Z" data-name="Янгиариқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[5][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/xiva-tumani">
                                        <path d="M255.5,282.67,251.33,289h-4.66l-24,12s-6,11.83-8.34,13.83-6.83,1-8.33,10.5.83,10.84.83,10.84l-1.33,5.5h-3.83v-4l-4.5-1.5s-3,5.5-3.34,8.66-4.5,17.67-4.5,17.67l.58,7.91a84.48,84.48,0,0,1-9.66-6.91c-7.25-6.25-37.75-30.75-51.5-38.5-4.64-2.61-10.35-5.48-15.84-8.67,0,0,4.59-8.16,6.26-10.5s.83-9,3-11.83,9.66-1.33,9.66-1.33l6.34-6.5c3.16.16,8.5,9.16,11.83,10.33s8.17-2.17,11.17-3.33,12.5-1.5,12.5-1.5l1.83-5.17-1.83-3.5,1.16-4.33,5.34.83,9.16,4.17s4,.16,7.34.16,9.16-2,9.16-2l-.16-3.66,1.83-4.34,7.67-.33,5.16,2.33,6.34.34,1.33-2.5-4-3.34,2.83-4.83H232l3.5,2.67L249.93,267Z" data-name="Хива тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[4][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/urganch-shahri">
                                        <path d="M321.74,233.58,314,249s-29.5-.33-30,1.83,6.5,8,6.5,8-1.17,8-3.67,9.67-13.66-1.33-21-1.5-15.9,0-15.9,0l-14.43,1.17L232,265.5h-5.17s-6.16-3.67-10.66-5a60.36,60.36,0,0,0-11.34-1.67l-4.33-5.33V243a6.74,6.74,0,0,0-5,1.33c-2.33,1.84-4.33,1-7-2.16s-1.17-11.84-3.17-13.84l24.77-22c6,3.24,15.24,2.91,18.4,6.21,3.83,4,9.17,2.83,11.17,2.17s2.33-9.5,10.26-10.5,7.24,5.33,8.74,7.16,5.5-.16,8.33-1.83,4-3.5,4.17-7-3-2.33-6.74-3.33-3.6-2.34-1.76-7.67-1.34-5.67-2.17-7.83.17-7.17,0-7.67,6.83,4.33,6.83,4.33c4.67-3.33-4-12.66-4.5-12.83S265,163,265,163l10,5.75s4.25,8.25,4.5,12,0,10.25,0,10.25,2.25,8.75,8.5,11.5a35.3,35.3,0,0,0,11.25,3s.75,13,4.25,17.5c6.5,8.25,14.25,6.5,14.25,6.5S319.53,231.24,321.74,233.58Z" data-name="Урганч шахри" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[2][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/qoshkopir-tumani">
                                        <path d="M228,273.67l-1.33,2.5-6.34-.34-5.16-2.33-7.67.33-1.83,4.34.16,3.66s-5.83,2-9.16,2-7.34-.16-7.34-.16l-9.16-4.17-5.34-.83L173.67,283l1.83,3.5-1.83,5.17s-9.5.33-12.5,1.5-7.84,4.5-11.17,3.33-8.67-10.17-11.83-10.33l-6.34,6.5s-7.5-1.5-9.66,1.33-1.34,9.5-3,11.83-6.26,10.5-6.26,10.5c-10.78-6.25-20.65-13.72-19.66-22.83,1.5-13.75.25-34.75,7-43s14.25-8.34,14.25-16.92c0-6.28-3.48-15.6-8.88-24.75,0,0,23.55-1,27.88,0,9,1.17,24.83,18.84,29.17,20s11.83.5,14.66,0,6-2.5,8-.5.5,10.67,3.17,13.84,4.67,4,7,2.16a6.74,6.74,0,0,1,5-1.33v10.5l4.33,5.33a60.36,60.36,0,0,1,11.34,1.67c4.5,1.33,10.66,5,10.66,5L224,270.33Z" data-name="Қўшкўпир тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[8][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/shovot-tumani">
                                        <path d="M210.1,206.29l-24.77,22c-2-2-5.16,0-8,.5s-10.33,1.17-14.66,0-20.17-18.83-29.17-20c-4.33-1-27.88,0-27.88,0A100,100,0,0,0,99,199c-9-11.75-15-18-14.75-27.75,0,0,5-20.75,8-22.5s5.25,3.5,9.75,2.75,3.75-.5,8,2,9,1.5,14,4.5a82.84,82.84,0,0,1,10.75,8.25h6.75l10,5.75,2.25-3s6.5,2.75,6.25-3a4.73,4.73,0,0,0-.13-.94s8.8-3.23,13.63-4.56,6.67,4.5,11.83,3.5a3.87,3.87,0,0,0,2-1s6.54,15,6.54,15.5,10.67,6,10.67,6-1.33,13,2.33,19.17A10.78,10.78,0,0,0,210.1,206.29Z" data-name="Шовот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[10][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/yangibozor-tumani">
                                        <path d="M271.17,202.5c-.17,3.5-1.34,5.33-4.17,7s-6.83,3.67-8.33,1.83-.82-8.16-8.74-7.16-8.26,9.83-10.26,10.5-7.34,1.83-11.17-2.17c-3.16-3.3-12.44-3-18.4-6.21a10.78,10.78,0,0,1-3.27-2.62c-3.66-6.17-2.33-19.17-2.33-19.17s-10.67-5.5-10.67-6-6.54-15.5-6.54-15.5c2.88-2.7,2-9.5,2-9.5s8.5-16.33,10.84-18,22.16-.33,22.16-.33l4.5-5,3.34,2.16-1,8.34c3,6.66,19.08,1.83,19.08,1.83v7l-2,5.5,4.5,8H265s-2.67,4.33-2.17,4.5,9.17,9.5,4.5,12.83c0,0-7-4.83-6.83-4.33s-.84,5.5,0,7.67,4,2.5,2.17,7.83-2,6.67,1.76,7.67S271.33,199,271.17,202.5Z" data-name="Янгибозор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[1][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/gurlan-tumani">
                                        <path d="M248.25,142.5s-16.08,4.83-19.08-1.83l1-8.34-3.34-2.16-4.5,5s-19.83-1.34-22.16.33-10.84,18-10.84,18,.84,6.8-2,9.5a3.87,3.87,0,0,1-2,1c-5.16,1-7-4.83-11.83-3.5s-13.63,4.56-13.63,4.56c-1.18-5-9.72-6.83-11.37-11.31-1.75-4.75-9.75-9.25-15.75-10.75s-16.5-2-20.25-9.25S107,123,107,123a24.07,24.07,0,0,1,9.5-10.08c7-4.17,11-6.67,11-6.67l8,.25,4.5,5.15,3.75-5.4-5.5-14.56L141,79s5.75.25,7.5,2.75S152.25,87,152.25,87H157a13.19,13.19,0,0,1,4-5.75c2.75-2,11.25-2.25,11.75-6.5A52.11,52.11,0,0,0,173,66.5L183.5,69l.75-9,5.5-1.5s1.75,7.75,2.5,7.75,4.5,6,5.5,6,6,2,7,2h8.75l7.5-4.5s5.75,3.75,6.25,4.5S229,86.5,227.25,94c0,0,5.5,11,5.25,14.5a98.6,98.6,0,0,1-1.75,10.25s8.75,17.75,9.5,17.75S248.25,142.5,248.25,142.5Z" data-name="Гурлан тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[6][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/hazorasp-tumani">
                                        <path d="M466.5,405c-9.5,4.75-32.75,4.25-37,2s-17.75-8.25-17.75-8.25S397,382,393.25,382a118.25,118.25,0,0,0-12,1.25S371,373,372,373c-.33,0-8.63.37-20.67.83,0-9.68,3.84-25,10.17-32s13.83-26,13.83-26l-3.66-10s7,1.34,9.66,0,5.88-11.25,5.88-11.25l8,13.17,11.75,1.5,12.5,11.5,24.75,8.75s3.5,7.25,4.25,7.5-4.25,22.5-4.25,22.5l3.25,8s9.5,5.25,12,6.75,15,10.5,15,10.5S476,400.25,466.5,405Z" data-name="Ҳазорасп тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[6][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/hazorasp-tumani">
                                        <path d="M471,373.75c1,0,7,2.25,7,2.25s-1.25-10,0-13.5,4.5-11.25,10.25-11S502,354,508.5,357.25s17.75,4,17.75,4,7.25-5.5,11.75-3.25c9.25,4.5,9.25,14.75,9.25,14.75s7.25,6,9.75,6h8.25S576.25,394,581,397s10.75,7.25,15.75,7.5c0,0,9.5,11.25,12.25,12.75s7.75,4.75,7.75,4.75,5,8.5,5.5,11.75-1.5,7.25-1.5,7.25,2.75,3.5,9,6,16.5,4.25,16.25,24c1.5.75,6.25,1.5,5.25,5.75s.52,9.75.14,17.75,1.11,14.5,1.11,21.75-1.25,16,0,18.5,6.25,10.75,7.75,14.75,4,9.25,4,9.25-1.25,15.5,2.5,21,8,9,10.75,16.5,6,11.25,6.5,16.75a27.9,27.9,0,0,0,5,15.5l95.25-74.75,11.75-31L733.25,449l-26.5,12.5s-23.5-24-40-42.75-64.5-78-64.5-78L584.5,331s-33.25-3-45.5-4.25-38.25-3.5-38.25-3.5l-23.5,17-6.25,19Z" data-name="Ҳазорасп тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default XorazmMap;