import { Popover } from "antd"
import { Link } from "react-router-dom"
import { useHospital } from "../../context/Context"
import LoadingPage from "../../pages/LoadingPage"

function ToshkentCityMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg height="500" viewBox="0 0 4481 4526" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[9][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/yunusobod-tumani">
                                        <path data-name="Юнусабадский район" d="M1718.5 134L1639 195L1712 340.5L1785 474L1948 649.5L2229 937.5L2119 1072L2160 1111L2139.5 1151.5L2305.5 1275.5L2281.5 1341.5L2279 1409L2249 1449.5V1494.5L2227.5 1530L2217.5 1569L2172.5 1673.5L2402.5 1774L2447 1721L2431.5 1688.5L2459 1640L2544 1644.5L2543 1554L2561.5 1555L2573.5 1507L2625 1485.5L2632 1460L2625 1443.5L2613.5 1419.5L2621 1416L2677.5 1388L2682 1373L2667.5 1339L2696 1297L2817 1284L2859 1378L2884 1365.5L2903 1346.5L2904.5 1312.5L2884 1273L2912.5 1190L3026 1015.5L3140.5 1019L3265 916L3263.5 907L3016.5 656.5L3002 616.5L2952.5 467L2915.5 403L2871 382.5L2661 403L2445 396L2348.5 337L2201.5 212L2088.5 134L1927 125L1791.5 121L1718.5 134Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[1][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/mirzoulugbek-tumani">
                                        <path data-name="Мирзо-Улугбекский" d="M2506 1833L2401.5 1774.5L2447 1721.5L2428.5 1693L2459 1642H2540.5L2542.5 1553.5L2561 1554.5L2572.5 1507L2581.5 1502L2624 1486L2632 1460L2613.5 1419.5L2646 1404L2676.5 1388.5L2682 1373.5L2667 1339.5L2695.5 1293.5L2817.5 1285L2859 1377.5L2903 1347.5L2905.5 1312.5L2880.5 1283.5L2911 1194.5L3027 1015L3140.5 1019L3266 916L3333.5 913L3396 939.5L3452 936.5L3494.5 881.5L3563.5 790.5H3602.5L3725.5 819L3881 762L3942 742L3999 753.5L4025.5 801.5L4011 962L4065 929.5L4189 1149L4020.5 1250.5L3982 1190.5L3896 1231.5L3881 1204.5H3812L3805.5 1235.5L3780 1259.5L3795.5 1325.5L3765 1362.5L3574 1573L3490 1646L3384.5 1726.5L3377.5 1755L3329.5 1849.5L3225 1872L3083.5 1711.5L2958 1700L2826.5 1628L2803.5 1700L2732.5 1767L2684.5 1755L2642 1812L2632 1855.5L2564 1849.5L2506 1833Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[8][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/shayxontohur-tumani">
                                        <path data-name="Шайхонтохурский район" d="M743 1166L749.449 1078.69L877.949 1071.69L1006.5 1046L1115 1038.5L1337.5 1130.5L1303.5 1216.5L1687 1506L1741 1444L1926.5 1445L2138 1149L2308 1273L2280.5 1342L2278 1408.5L2271 1419L2249 1449.5V1494L2225.5 1538.5L2220.5 1563L2174.5 1674L2184.5 1678.5L2145 1748H2101V1770L1837.5 1758L1691 1992.5L1564 1940L1519 1968H1245.5L1151.5 1925L1132.5 1484L785.5 1461L783.5 1404.5L755.5 1383L742 1393L721.5 1408.5L662 1452L661 1417.5L677 1389H695L729.5 1315.5L737 1266.5L743 1166Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[3][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/olmazor-tumani">
                                        <path data-name="Алмазарский район" d="M749.5 1027V1078.5L868 1074L1007.5 1046.5L1115 1038.5L1338.5 1130.5L1304.5 1217L1687 1505.5L1740.5 1444H1926L2038 1287L2114 1186.5L2165 1114.5L2119.5 1072L2230 936.5L2095 800L1794.5 484.5L1757.5 423.5L1664.5 240.5L1641 188L1474 330.5L1394 340.5L1377 402L1332 441.5L1166.5 584.5L997 731.5L841 858C821.5 880.167 781.7 924.6 778.5 925C775.3 925.4 757.833 993.167 749.5 1027Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[10][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/yakkasaroy-tumani">
                                        <path data-name="Якасарайский район" d="M1439 2834.5L1456 2869.5L1670.5 2731L1766 2688L1958 2637.5L2149 2591.5L2283 2398L2164 2275L2191 2253.5L2179.5 2216.5L2228 2186V2158L2262 2126.5L2213 2067L2224 2052L2185.5 1931.5L2228 1818L2139.5 1770H2100L2058 1833L2011 1876L2006.5 1910.5L1992 1931.5L1940.5 1969L1836.5 2036V2078.5L1781.5 2136.5V2195.5L1735.5 2258V2339L1697.5 2360L1600 2443V2476L1557 2548L1545.5 2586L1504.5 2649.5L1496 2692L1469.5 2746L1439 2834.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[5][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/uchtepa-tumani">
                                        <path data-name="Учтепинский район" d="M142 2645.5L112.5 2624L142 2562.5L176.5 2545.5L185.5 2506L293.5 2347L306 2275.5L268.5 2233.5V2205L367 2124L378.5 2067.5L503.5 1893.5L492 1713L585.5 1559.5V1499.5L604.5 1455L630.5 1466.5L658 1455L755.5 1382.5L784 1404L790 1455L1135 1482.5L1150.5 1905L1162.5 2328.5L1221.5 2381L1153.5 2449L1128.5 2456L937.5 2498L859 2573L834 2551.5L788.5 2597L729.5 2573L680.5 2624L510.5 2724L484 2653.5L492 2347L285.5 2562.5L193.5 2614L161.5 2606L142 2645.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[7][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/chilonzor-tumani">
                                        <path data-name="Чиланзарский район" d="M709.5 2900L511 2724L685 2620L730.5 2571L787.5 2597L834 2550L860 2572.5L928.5 2500.5L1111.5 2452L1162 2440L1220.5 2382L1162 2332L1151 1925.5L1246 1969.5H1512L1564.5 1937.5L1693 1990L1832.5 1757.5L2103 1770.5L2052 1844.5L2013 1876L2010.5 1924L1838 2040V2079.5L1784 2134.5V2192.5L1738 2255L1736.5 2338L1699.5 2358.5L1605 2443V2480L1555 2553L1550 2583.5L1506 2650.5L1501 2684L1480 2725.5L1442.5 2831.5L1454.5 2871L1309 2984.5L1101.5 3157.5L997.5 3277.5L884 3437L723 3206.5L757 3135.5L769.5 3074.5L741.5 2945.5L709.5 2900Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[4][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/sirgali-tumani">
                                        <path data-name="Сергилийский район" d="M892.5 3465.5L884 3436.5L991.5 3285.5L1101 3152.5L1259 3022.5L1500 2833.5L1670.5 2729L1738.5 2694.5L1874.5 2659L2119.5 2600L2366.5 2533L2468 2530.5L2581.5 2651H2631.5L2635 2683L2704 2696L2773 2651L2817.5 2717.5L2722 2779L2682 2790L2696.5 2826.5L2700 2876L2691 2882L2689 3039L1551 3045.5C1530.17 3056.67 1487.6 3078.8 1484 3078C1480.4 3077.2 1462.17 3109.67 1453.5 3126L1450 3196.5L1558 3424.5L1592 3413.5L1595 3350.5L1650 3308L1646 3257L1684.5 3246L1700 3190.5H1839L1884 3572L2125.5 3440.5L2253 3605L2318 3583.5L2367.5 3529L2430 3515.5L2377 3578L2392.5 3634L2385.5 3689L2329 3752L2347.5 3812.5L2197 3901.5L2047.5 4030L2097 4099L2123.5 4163.5L2119.5 4238.5L2087.5 4429L1785.5 4419L1755 4458L1613.5 4303.5L1702.5 4192V4126L1730 4043.5L1259 4090.5L1204.5 4114.5L1163.5 4192L959 4209L1064.5 4013.5L1289.5 3900L1259 3803.5H1134L1103.5 3769.5L1325 3409L1292 3321.5L1333 3251L1431 3078L1491 3009.5L1500 2909L1486.5 2881L1467 2877.5L1461.5 2970.5H1423.5L1333 3078L1307 3060.5L1292 3105.5H1264.5L1250 3126L1276.5 3155.5L1268 3176.5L1229.5 3188L1238.5 3221.5L1200 3249V3278.5L1164 3302L1144 3364L1102.5 3416L1072 3435.5L1044.5 3484L1072 3548.5L1039.5 3614.5L1064.5 3644L1044.5 3673.5L877 3580.5V3513.5L892.5 3465.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[0][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/bektemir-tumani">
                                        <path data-name="Бектемирский район" d="M2430 3664.5V3588.5L2463.5 3554.5L2481.5 3494L2548 3438.5L2680 3331L2759 3296.5L2781.5 3267.5L2833 3258.5L2903 3165L3010.5 3161L3021.5 3128.5L3072 3101L3103.5 3161L3228 3028.5L3337.5 2999.5L3408 2949.5L3470.5 2870.5L3603 2596H3650L3687 2557.5L3753.5 2488.5L3797 2460.5L3822 2475.5L3832 2539L3808.5 2617L3817 2660H3867L3945 2626L4059.5 2636.5L4064.5 2660L3867 2846.5L3966.5 2949.5L4000.5 3071L3901.5 3151L3847 3065.5L3729.5 3077.5L3719 3058.5L3734.5 2999.5L3706 2981L3645 3045.5L3580.5 3053.5L3375 3264.5L3368.5 3388L3327.5 3409L3261 3331L3232 3334.5L3228 3374.5L3154 3432.5L3162.5 3391.5L3099.5 3380L3092 3432.5L3047 3427L2986 3494L2957.5 3400L2924 3488L2820 3588.5L2679 3647L2608.5 3719.5L2567.5 3707.5L2534.5 3755.5L2405.5 3802L2387 3767.5L2430 3664.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[11][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/hamza-tumani">
                                        <path data-name="Хамзинский район" d="M3104.5 3160.5L3066 3097.5L3045 3051.5L2979.5 2881L2911 2711L2822 2498L2751.5 2365.5L2746.5 2313.5L2736 2276.5L2713.5 2221L2664.5 2148.5L2528.5 1975L2375 1791.5L2393 1762L2440 1796L2554.5 1844.5L2633 1853.5L2642 1809.5L2650 1791.5L2685.5 1753.89L2729.5 1766L2797 1694L2811.5 1659.5L2826 1627.5L2954.5 1694L3084 1712L3228 1870L3329.5 1847.5L3377 1755L3383 1843.5L3513.5 1870L3534.5 1939L3673 2118.5L3710.5 2126.5V2166.5L3751 2171.5L3772.5 2244.5L3692 2279L3723.5 2423L3757.5 2484.5L3650 2596L3605.5 2598L3454.5 2904L3394 2966L3341.5 2999.5L3249.5 3022.5L3214 3045.5L3104.5 3160.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[13]?.districts[2][`name_${lang}`]}>
                                    <Link to="/toshkent-shahri/mirobod-tumani">
                                        <path data-name="Мирабадский район" d="M2276.5 2557L2149 2591.5L2283.5 2401L2163.5 2275.5L2190 2254L2177.5 2214L2225.5 2185.5V2157L2264 2129L2212.5 2066.5L2218.5 2053.5L2186.5 1927.5L2231.5 1819L2143 1774.5L2102 1770V1748.5H2139.5L2185 1679L2393 1766.5L2376.5 1792.5L2420.5 1845L2482 1915.5L2529 1967L2608 2076L2702.5 2197L2730.5 2255L2746.5 2307.5L2751 2365L2819 2490.5L2876 2630.5L3036 3018L3068.5 3102.5L3021.5 3129.5L3010.5 3160L2969.5 3163L2943.5 3131.5L2875.5 3058.5L2832.5 3039.5L2688.5 3038V2886L2700 2873L2695 2822L2681.5 2791L2719.5 2781L2817 2717.5L2773 2651L2703.5 2697L2635 2683.5L2631 2653L2581 2651L2467.5 2530L2367 2533.5L2276.5 2557Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g>
                            {/* <g className="grouped">
                                <Popover title="Yangihayot tumani">
                                    <Link to="/toshkent-shahri/yangihayot-tumani">
                                        <path d="M1627.5 2763.5L1458 2868.5L1440.5 2821L1458 2763.5L1502 2685L1509 2639L1544 2596.5L1554 2557L1602.5 2479.5V2441.5L1688.5 2360.5L1736 2338V2263L1783.5 2193L1774.5 2140L1834.5 2074V2040L2002.5 1921.5V1883L2057 1829.5L2098.5 1775H2150.5L2225.5 1819.5L2192 1927L2220 2054L2212.5 2067.5L2264 2131L2225.5 2153.5V2180L2180.5 2218L2192 2255.5L2164 2278L2282.5 2401L2150.5 2589.5L1961.5 2639L1825.5 2670L1699.5 2713L1627.5 2763.5Z" fill="transparent" stroke="#fff"
                                            strokeWidth="6"></path>
                                    </Link>
                                </Popover>
                            </g> */}
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default ToshkentCityMap;