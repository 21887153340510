import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function LoadingPage() {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 45,
            }}
            spin
        />
    );
    return (
        <div className='spin-parent'>
            <Spin size='large' />
        </div>
    )
}

export default LoadingPage
