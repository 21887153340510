import { Breadcrumb, Col, Layout, Row, Select, theme } from 'antd';
import Maps from '../mapscontainer/Maps';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SirdaryoMap from '../regionmaps/SirdaryoMap';
import GMap from '../mapscontainer/GMap';
import { regions } from '../mapscontainer/regions';
import MyMenu from './MyMenu';
import { DoubleRightOutlined } from '@ant-design/icons'
import { useHospital } from '../../context/Context';
import SamarqandMap from '../regionmaps/SamarqandMap';
import { memo } from 'react';
import { langWords } from '../../context/LangWords';
import ToshkentCityMap from '../regionmaps/ToshkentShahar';
import QoraqalpogistonMap from '../regionmaps/QoraqalpogistonMap';
import AndijonMap from '../regionmaps/AndijonMap';
import BuxoroMap from '../regionmaps/BuxoroMap';
import JizzaxMap from '../regionmaps/JizzaxMap';
import QashqadaryoMap from '../regionmaps/QashqadaryoMap';
import NavoiyMap from '../regionmaps/NavoiyMap';
import NamanganMap from '../regionmaps/NamanganMap';
import SurxondaryoMap from '../regionmaps/SurxondaryoMap';
import ToshkentVilMap from '../regionmaps/ToshkentVil';
import FargonaMap from '../regionmaps/FargonaMap';
import XorazmMap from '../regionmaps/XorazmMap';
import LoadingPage from '../../pages/LoadingPage';


const nameComponents = {
    'sirdaryo-viloyati': <SirdaryoMap />,
    'samarqand-viloyati': <SamarqandMap />,
    'qoraqalpogiston-respublikasi': <QoraqalpogistonMap />,
    'andijon-viloyati': <AndijonMap />,
    'buxoro-viloyati': <BuxoroMap />,
    'jizzax-viloyati': <JizzaxMap />,
    'qashqadaryo-viloyati': <QashqadaryoMap />,
    'navoiy-viloyati': <NavoiyMap />,
    'namangan-viloyati': <NamanganMap />,
    'surxondaryo-viloyati': <SurxondaryoMap />,
    'toshkent-viloyati': <ToshkentVilMap />,
    'fargona-viloyati': <FargonaMap />,
    'xorazm-viloyati': <XorazmMap />,
    'toshkent-shahri': <ToshkentCityMap />
}


const { Header, Content, Sider } = Layout;
const MyLayout = () => {
    const {
        breadcrumbItems,
        thisRouteRegion,
        thisRouteDistrict,
        showColNumbers,
        setShowColNumbers,
        lang,
        setLang,
        zones
    } = useHospital()
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <>
            {
                zones.length > 0
                ?
                <Row style={{ position: 'relative' }}>
                    <div className='menu-showBtn'>
                        <button
                            className='showBtn'
                            onClick={() => {
                                setShowColNumbers(!showColNumbers)
                            }}
                        >
                            Menu
                            <DoubleRightOutlined />
                        </button>
                    </div>
                    <Col className={`gutter-row ${showColNumbers ? 'my-primary-menu' : ''}`} xs={showColNumbers ? 24 : 0} lg={showColNumbers ? 6 : 0} xl={showColNumbers ? 5 : 0}>
                        <Sider
                            width={'100%'}
                            style={{
                                overflow: 'auto',
                                position: 'relative',
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <Header
                                style={{
                                    height: "75px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Link className="logo" to='/'>
                                    <img className='logo__img' src="/assets/images/logo.png" alt="" />
                                    <span className='logo__text'>
                                        {langWords[`companyName_${lang}`]}
                                    </span>
                                </Link>
                            </Header>
                            <div
                                className="menu-parent"
                                style={{
                                    height: 'calc(100vh - 75px)',
                                    overflow: 'auto',
                                    background: '#072853'
                                }}
                            >
                                <MyMenu />
                            </div>
                        </Sider>
                    </Col>
                    <Col className='gutter-row' xs={24} lg={showColNumbers ? 18 : 24} xl={showColNumbers ? 19 : 24}>
                        <Layout hasSider>
                            <Layout>
                                <Header
                                    style={{
                                        height: "75px",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: "space-between",
                                        paddingInline: '25px'
                                    }}
                                >
                                    <Breadcrumb items={breadcrumbItems} />
                                    <Select
                                        defaultValue={`${lang}`}
                                        style={{
                                            width: 64,
                                            background: '#001529'
                                        }}
                                        placement='bottomRight'
                                        onChange={(e) => {
                                            setLang(e)
                                            localStorage.setItem('lang', e)
                                        }}
                                        options={[
                                            {
                                                value: 'uz',
                                                label: "UZ"
                                            },
                                            {
                                                value: 'ru',
                                                label: 'RU',
                                            },
                                            {
                                                value: 'en',
                                                label: 'EN',
                                            }
                                        ]}
                                    />
                                </Header>
                                <Content
                                    style={{
                                        margin: 0,
                                        minHeight: 280,
                                        background: colorBgContainer,
                                        overflow: 'hidden'
                                    }}
                                >
                                    {
                                        location.pathname === '/'
                                            ?
                                            <Maps />
                                            :
                                            location.pathname === `/${thisRouteRegion}`
                                                ?
                                                nameComponents[thisRouteRegion]
                                                // <SirdaryoMap />
                                                :
                                                location.pathname === `/${thisRouteDistrict}`
                                                    ?
                                                    <GMap />
                                                    :
                                                    navigate('/error')
                                    }
                                </Content>
                            </Layout>
                        </Layout>
                    </Col>
                </Row >
                :
                <LoadingPage />
            }
        </>
    );
};
export default memo(MyLayout);