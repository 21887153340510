import React, { useEffect, useMemo, useState } from 'react'
import { GoogleMap, Marker, InfoWindow, Polygon } from '@react-google-maps/api';
import mapsStyles from './mapsStyles'
import { UZB_COORDINATES } from './staticCoordinates'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Drawer, List, Spin } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons'
import { useHospital } from '../../context/Context';
import { langWords } from '../../context/LangWords';

const containerStyle = {
    width: '100%',
    minHeight: 'calc(100vh - 65px)',
};

function MyComponent() {
    const {
        changedThisHospital,
        setChangedThisHospital,
        districtHospitals,
        lang,
        isLoaded,
        isPoliclinicLoading,
        districtID
    } = useHospital()

    const location = useLocation()

    const [regionCoordinate, setRegionCoordinate] = useState(null)
    const [thisRegionCenter, setThisRegionCenter] = useState({})
    const [openDrawers, setOpenDrawers] = useState(false);
    const [showIW, setShowIW] = useState(null)
    // ///////////////////
    const [clientPosition, setClientPosition] = useState(null)

    // ////////////////////
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(getClientPosition);

        // return () => localStorage.removeItem('chekcedHospital')
    }, [])
    useEffect(() => {
        setChangedThisHospital(JSON.parse(localStorage.getItem('chekcedHospital')))

        // return () => localStorage.removeItem('chekcedHospital')
    }, [openDrawers])
    function getClientPosition(position) {
        setClientPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
    }

    const locationPathnames = location.pathname.split('/')[2].split('-')

    useMemo(() => {
        let f = UZB_COORDINATES.filter(coor => coor.SimpleData[4].toLowerCase() === locationPathnames[0].toLowerCase() && coor.SimpleData[7].toLowerCase() === locationPathnames[1].toLowerCase() ? coor : '')
        let thisRegionCoordinates = null
        thisRegionCoordinates = f[0].Polygon.coordinates.split(" ")
        let regCoor = thisRegionCoordinates.map(a => {
            return { lng: +a.split(",")[0], lat: +a.split(",")[1] }
        })
        setRegionCoordinate(regCoor)
        setThisRegionCenter(f[0].center)
    }, [location.pathname])
    const showDrawer = () => {
        setOpenDrawers(true);
    };
    const onClose = () => {
        setOpenDrawers(false);
    };
    return isLoaded && !isPoliclinicLoading && districtID && districtHospitals ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={thisRegionCenter}
            zoom={7}
            options={{
                styles: mapsStyles,
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
        >
            {
                regionCoordinate && <Polygon
                    paths={regionCoordinate}
                    options={{
                        strokeColor: "green",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: "green",
                        fillOpacity: 0.4
                    }}
                />
            }
            {changedThisHospital && <Drawer title={changedThisHospital[`name_${lang}`]} placement="right" onClose={onClose} open={openDrawers}>
                <div>
                    <List>
                        <List.Item>
                            ✅
                            <span className='organization-type'>{langWords[`addressText_${lang}`]}:</span>
                            <span className='organization-val'>
                                {changedThisHospital[`address_${lang}`]},
                                {changedThisHospital[`target_${lang}`]}
                            </span>
                        </List.Item>
                        <List.Item>
                            ✅
                            <span className='organization-type'>{langWords[`workingTimeText_${lang}`]}: </span>
                            <span className='organization-val'>{changedThisHospital.work_time}</span>
                        </List.Item>
                        <List.Item>
                            ✅
                            <span className='organization-type'>{langWords[`peopleNum_${lang}`]}: </span>
                            <span className='organization-val'>{changedThisHospital.population}</span>
                        </List.Item>
                        <List.Item>
                            ✅
                            <span className='organization-type'>{langWords[`sectionsText_${lang}`]}: </span>
                            <span className='organization-val'>
                                {
                                    changedThisHospital[`departments_${lang}`]
                                }
                            </span>
                        </List.Item>
                        <List.Item>
                            ✅
                            <span className='organization-type'>{langWords[`receptionText_${lang}`]}:</span>
                            <a href={`tel: ${changedThisHospital?.phone}`} className='organization-val'>{changedThisHospital?.phone}</a>
                        </List.Item>
                        <List.Item>
                            <Button
                                className='organization-btn'
                                type="primary"
                                icon={<EnvironmentOutlined />}
                                size="large"
                                // disabled={clientPosition ? false : true}
                                onClick={(ev) => {
                                    window.navigator.geolocation.getCurrentPosition(getClientPosition)
                                    if (clientPosition == null) {
                                        ev.preventDefault()
                                        alert("Ushbu sayt sizning joylashuvingizni aniqlash huquqiga ega emas.")
                                    } else {
                                        window.location.href = `https://www.google.com/maps/dir/${clientPosition?.lat},${clientPosition?.lng}/${changedThisHospital?.latitude},${changedThisHospital?.longitude}`
                                    }
                                }}
                            // href={`https://www.google.com/maps/dir/${clientPosition?.lat},${clientPosition?.lng}/${changedThisHospital?.latitude},${changedThisHospital?.longitude}`}
                            >
                                {langWords[`marshrutText_${lang}`]}
                            </Button>
                        </List.Item>
                    </List>
                </div>
            </Drawer>}
            {
                districtHospitals.length > 0
                    ?
                    districtHospitals.map(policlinic => {
                        return <Marker
                            key={policlinic.id}
                            position={{ lat: parseFloat(policlinic.latitude), lng: parseFloat(policlinic.longitude) }}
                            animation={window.google.maps.Animation.DROP}
                            options={{
                                zIndex: 10000,
                            }}
                            title={policlinic[`name_${lang}`]}

                            onClick={() => {
                                showDrawer()
                                setShowIW(true)
                                setChangedThisHospital(policlinic)
                                localStorage.setItem('chekcedHospital', JSON.stringify(policlinic))
                                if (clientPosition == null) {
                                    window.navigator.geolocation.getCurrentPosition(getClientPosition)
                                }
                            }}

                            onMouseOver={() => {
                                setShowIW(true)
                                setChangedThisHospital(policlinic)
                                localStorage.setItem('chekcedHospital', JSON.stringify(policlinic))
                            }}
                        />
                    })
                    :
                    <div className='spin-parent'>
                        <Spin size='large' />
                    </div>
            }
            {
                changedThisHospital && showIW && <InfoWindow
                    position={{ lat: parseFloat(changedThisHospital.latitude), lng: parseFloat(changedThisHospital.longitude) }}
                    style={{
                        bottom: '36px'
                    }}
                    onCloseClick={() => setShowIW(null)}
                >
                    <div>{changedThisHospital[`name_${lang}`]}</div>
                </InfoWindow>
            }
        </GoogleMap >
    ) : <div className='spin-parent'>
        <Spin size='large' />
    </div>
}

export default React.memo(MyComponent)