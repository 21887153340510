import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function NavoiyMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[2][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/qiziltepa-tumani">
                                        <path d="M471,620.33a52.15,52.15,0,0,1-6.83,4.17c-3.17,1.5-5,1.83-6.34-1.33l-.16-6.5-3-10.84-9.34-1.66V590.5s-9.66-1.33-11.83-3.33a6,6,0,0,1-2.17-4h-2.5l-1.5,3.33-5-3,.17-3-6.17-2.33-2.5-4.17-3.66-.33-1.34-7.34-3.5-3s-1.5-9.83,2-13.16,14.17-9.34,14.17-9.34l1.67-10.16a21.49,21.49,0,0,0,7.44-2l6.57,1.38s2.82,4.74,3.65,6.74-.83,6,2,10.34,6.5,8.83,7.5,10.5,0,5,0,5,5,0,7,3.83a13.71,13.71,0,0,1,1,8.33l3.84,3.17s7.66,10.83,7.83,14.33A52.82,52.82,0,0,1,469,605c-.83,3.17-1,10.17-1,10.17Z" data-name="Қизилтепа тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[1][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/karmana-tumani">
                                        <path d="M517.83,548.83a24.66,24.66,0,0,1-5.5,6.67L507,550.33s-4.17.67-5.83-2.66h-3.5v3.16l-5.5,9.67,3.83.5a6.61,6.61,0,0,1-.5,5.83h-3l.67,3.17h2a4.17,4.17,0,0,1-1,4.17c-1.84,1.66-2,2.66-2,2.66l-6.34-1.66-2.16-2.34-1.84,1.5-4-2.66-1,2.5-3.33-.34s2.33,3.5-.17,4.84-4-1-4.5-.84-6.66.17-6.66.17l-3.84-3.17a13.71,13.71,0,0,0-1-8.33c-2-3.83-7-3.83-7-3.83s1-3.34,0-5-4.66-6.17-7.5-10.5-1.16-8.34-2-10.34-3.65-6.74-3.65-6.74L460.67,535s5.16,6,8.5,8,18.16-1.17,18.16-1.17l10.5,1.17,1.64-7.57,13.7,4.57H519C519.12,543.15,519.09,546.65,517.83,548.83Z" data-name="Кармана тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[7][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/tomdi-tumani">
                                        <path d="M458.5,414.5c-1.17-1.67-5.33-1.67-8.5-2.5s-3.83,2.5-3.83,2.5l-2.5-.83,4.66-27.34a52.37,52.37,0,0,0-10.5-4c-5.83-1.5-23.66-.83-29.66-.83a63.21,63.21,0,0,1-17-2.5c-3.34-1.17-6.34.67-10.84.5s-8.66-2.17-17-2.67c-6.69-.4-34.67,5.33-47,7.25a27.4,27.4,0,0,1-5.68.59c-3-.67-15.67-17.5-15.67-17.5l4-5L301,319s6,3.17,12.33,1.5,6.5-10.17,6.5-10.17h33.34s-10.17-38.16-10.5-38.66,40,.66,40,.66l-4.84-114.16,36.34,1-8.39-96.28c12.09-6,30.61-15,35.22-15.89,7-1.33,13.33-6.67,18.5,2.67S470,69.33,474,75.33s34,31.34,34,31.34,17.33,58.66,18.33,58.66S553,153.67,553,153.67l1.33,83s-7.33-7.34-7.33-5.34v15L545,250s-1.33,40.33,0,40,51.33,6.33,51.33,6.33l7.67,86h4.67L619,413.67h-8c.19,0,.49,4.64.72,8.58H609l-.5,5.25-1.92-.42v-3.75l-4,.42-1.91,5.58-1.42-.41v-4.5l-1.58.33.41,3-1,1.58h-2.16l-.09-2.16.92-1.84-.5-1.58-2.08.33-.75,2.34,1.08,1.91-1.92.42-.58-2.08v-1.59l-2.17.71-1.08,1.63-3.17-.5L582,428.08l-2-1.75,1.42-1.16,2.08-.17-1.75-1.83-2.83.83,1.08-2.58h-1.75l-2.75,1.16-1.5.17v-2l-3.08.67-.84,1.5-3.5-.34a6.84,6.84,0,0,1-1.91.75c-.34-.08-8.34.25-8.34.25L553,426.17v1.75l-2.5-.34s-.33-2.08-.5-1.83-3,2.17-3,2.17l-3.17.16-2.41.84-1.09-1.5-3.16.41s.08,1.34-.42,1.5-3.75-1.5-3.75-1.5-4.83-2-5.33-2S517,425,517,425l-4.5,4.83L481,415.5a9.85,9.85,0,0,1-1.67,6c-2,3-15,1.83-16.5,1.5S459.67,416.17,458.5,414.5Z" data-name="Томди тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[6][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/nurota-tumani">
                                        <path d="M625,467s-6.33,3.67-7.33,10,.33,17.67-.67,21-2,7-2,7l-7.67-1.17-1-2.33,2.5-1.17-.5-2-4.5.17s-1.16-4.83-3.83-5-5.83-.67-7-2.5-.33-6-.33-6a21.13,21.13,0,0,1-8.17-.5c-3.5-1.17-6.67-2.83-6.67-2.33s1.17,5.66.5,7.16-6,6.34-6,6.34l-.33,4.16,1.17,1.67-1.5,1.67v4.16l1.33,2-.5,1.84h-3.17l-.16,3,1.16,2.16L569.5,520l.83,3.5L567,526.33l-7.5-4.66-2.17-3.5-8.83-1.34-1.33-5.5L543,508.67l-1.83,5.83-4.5,1v-3.17L531,511s.17,3.67-1.33,4.67-5,0-7,3.66-3.5,6.5-1.67,7.84,4.17,1.83,3.33,4.33-4.66.17-5.5,1.83c-.41.83-.05,3.58.12,6.67h-5.78l-13.7-4.57-7.3-2.43-2.67-2.67-2.33,5.34L481,534l1.83-7.17-16.25-10.39-8.25-5.27,1.17-7,1.33-9.84,4.5-1.66,1-6.17,1.84-2.83V477.5l12.5,1.33-1.17-4.5-.33-11.66,1.5-3.34V455s3.83-3.5,11.16-4.33,23.84,6,23.84,6L522,456l8.82-22.59,3.76,2.42,1,3.42,4.75-.33,2.09,1.5,2.41-.75,1.75,1.83,9.84-.08.83,1.33,3.58-.17,3.59-3.25,1.5,2.17,2.25-2.25,1.91,2.25,4-2.17,5.84-.16.41-2.5h2l.59,2.08h2.66l1.59-.67,2,1.75v2.92h1.91l1.84-2.25s3,.92,2.58,1.25-2.42,1.83-2.42,1.83l3.67.59.75,1.41s-2.08,1.17-1.75,1.25,4,0,4,0l.58-2.66s1.92-.09,2.25.25.17,1.41.17,1.41l2.5-.5,2.25,1.54h2.58l3.34-2,2,.59a18.64,18.64,0,0,0,1.5,2.83,17.9,17.9,0,0,0,2.08,0v-3.5l4.67.58Z" data-name="Нурота тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[3][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/konimex-tumani">
                                        <path d="M466.58,516.44l-2.91,4.39-3,14.17-23.49-4.91-6.57-1.38a4.6,4.6,0,0,0,2.72-3.38c.5-4.66-2.33-5.33-2.33-5.83s1.5-8,1-7.83-3.67-4-3.67-4,1.34-7.84,4.5-8.84,9.5,2,11,3.67l15.67,1.67-1.17,7Z" data-name="Конимех тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[3][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/konimex-tumani">
                                        <path d="M533,427.83,522,456l-6.33.67s-16.5-6.84-23.84-6S480.67,455,480.67,455l-7.17,2.5-8.83-3.33s-16.17.66-20,0-10.5-2.5-10.5-2.5L424.5,445l-3.33-28.5-4.84-.17.5,9.17s-10.66,2.5-14,2.17-15.33.83-15.33.83v8.83L384.83,444l-16.33.33-15.83-4.66v-17l-5-4.5L341.5,420,328,449.33l-12.17,2L313.5,445l-22.17-19.5s2.34-12.17,4.67-17.33,12.67-8.5,13.17-8.67,9,3.17,9,3.17l-1.82-18.59c12.31-1.92,40.29-7.65,47-7.25,8.34.5,12.5,2.5,17,2.67s7.5-1.67,10.84-.5a63.21,63.21,0,0,0,17,2.5c6,0,23.83-.67,29.66.83a52.37,52.37,0,0,1,10.5,4l-4.66,27.34,2.5.83s.66-3.33,3.83-2.5,7.33.83,8.5,2.5,2.83,8.17,4.33,8.5,14.5,1.5,16.5-1.5a9.85,9.85,0,0,0,1.67-6l31.5,14.33L517,425s10.17.83,10.67.83S533,427.83,533,427.83Z" data-name="Конимех тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[8][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/uchquduq-tumani">
                                        <path d="M342.67,271.67c.33.5,10.5,38.66,10.5,38.66H319.83s-.16,8.5-6.5,10.17S301,319,301,319l-2,43.17-4,5s12.67,16.83,15.67,17.5a27.4,27.4,0,0,0,5.68-.59l1.82,18.59s-8.5-3.34-9-3.17S298.33,403,296,408.17s-4.67,17.33-4.67,17.33l-37.66-18.67-12.17.67s-6.83-17.33-9-20.33-21.33-23.34-21.33-23.34l-5.34-15.16S189.17,353,185,353.83s-21.34,3.05-21.83,3.17c.5,0-28.17-41.83-30.67-46.5S121,287.33,121,287.33s14.5-8.66,18-10,15.33-6.5,15.33-6.5-4.66-3.83-5.33-5.5-7.5-20-7.5-20-.17-31.83,4.83-48.66l58-92.34-22.16-9.66-11.5-16L281,58s76.67,10.67,114,10.33c0,0,4.53-2.32,10.78-5.44l8.39,96.28-36.34-1,4.84,114.16S342.33,271.17,342.67,271.67Z" data-name="Учқудуқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[9][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/xatirchi-tumani">
                                        <path d="M567.83,546.33l-4.33,7.17.83,3-1.16,2.17s-4.5.66-5.84,1-1.66,2.83-1.66,2.83a23.16,23.16,0,0,0-7.34.67c-3.33,1-4,2.83-4,2.83h-5.16l-3.5-1.5A3.39,3.39,0,0,1,532,566c-2.33-.67-3.17-3.67-3.17-3.67s-8.5,1.5-12.5-1.83-4-5-4-5a24.66,24.66,0,0,0,5.5-6.67c1.26-2.18,1.29-5.68,1.12-8.83s-.53-5.84-.12-6.67c.84-1.66,4.67.67,5.5-1.83s-1.5-3-3.33-4.33-.33-4.17,1.67-7.84,5.5-2.66,7-3.66S531,511,531,511l5.67,1.33v3.17l4.5-1,1.83-5.83,4.17,2.66,1.33,5.5,8.83,1.34,2.17,3.5,7.5,4.66,1.33,3.34s-6.66,6.83-7.5,10-.83,8.66,1.5,9,3.17-3.17,3.17-3.17Z" data-name="Хатирчи тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[5]?.districts[4][`name_${lang}`]}>
                                    <Link to="/navoiy-viloyati/navbahor-tumani">
                                        <path d="M499.47,535.43,497.83,543l-10.5-1.17S472.5,545,469.17,543s-8.5-8-8.5-8l3-14.17,2.91-4.39,16.25,10.39L481,534l6.17,1.67,2.33-5.34,2.67,2.67Z" data-name="Навбаҳор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <path d="M623.67,445.33l-4.67-.58v3.5a17.9,17.9,0,0,1-2.08,0,18.64,18.64,0,0,1-1.5-2.83l-2-.59-3.34,2H607.5l-2.25-1.54-2.5.5s.17-1.08-.17-1.41-2.25-.25-2.25-.25l-.58,2.66s-3.67.09-4,0,1.75-1.25,1.75-1.25l-.75-1.41-3.67-.59s2-1.5,2.42-1.83-2.58-1.25-2.58-1.25l-1.84,2.25h-1.91v-2.92l-2-1.75-1.59.67h-2.66l-.59-2.08h-2l-.41,2.5-5.84.16-4,2.17-1.91-2.25-2.25,2.25-1.5-2.17-3.59,3.25-3.58.17-.83-1.33-9.84.08-1.75-1.83-2.41.75-2.09-1.5-4.75.33-1-3.42-3.76-2.42,2.18-5.58s3.25,1.67,3.75,1.5.42-1.5.42-1.5l3.16-.41,1.09,1.5,2.41-.84,3.17-.16s2.83-1.92,3-2.17.5,1.83.5,1.83l2.5.34v-1.75l3.33-2.59s8-.33,8.34-.25a6.84,6.84,0,0,0,1.91-.75l3.5.34.84-1.5,3.08-.67v2l1.5-.17,2.75-1.16H580L578.92,424l2.83-.83L583.5,425l-2.08.17L580,426.33l2,1.75,2.58-1.16,3.17.5,1.08-1.63,2.17-.71v1.59l.58,2.08,1.92-.42-1.08-1.91.75-2.34,2.08-.33.5,1.58-.92,1.84.09,2.16h2.16l1-1.58-.41-3,1.58-.33v4.5l1.42.41,1.91-5.58,4-.42v3.75l1.92.42.5-5.25h2.72c.16,2.9.28,5.42.28,5.42Z" fill="rgb(188, 222, 247)" stroke="#fff" strokeWidth="2"></path>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default NavoiyMap;