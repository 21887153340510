import { createContext, useContext, useState, useEffect } from "react";
import axios, { getAxios } from "../utils/axios";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import { langWords } from "./LangWords";

const HospitalContext = createContext()

export const HospitalProvider = ({ children }) => {
    const navigate = useNavigate()
    const { isLoaded, loadError } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    })
    const location = useLocation()
    const [thisRouteRegion, setThisRouteRegion] = useState('')
    const [thisRouteDistrict, setThisRouteDistrict] = useState('')
    const [showColNumbers, setShowColNumbers] = useState(false);
    const [changedThisHospital, setChangedThisHospital] = useState(null)
    const [districtID, setDistrictID] = useState(null)
    const [zones, setZones] = useState([]);
    const [lang, setLang] = useState('uz')
    const [districtHospitals, setDistrictHospitals] = useState([])
    const [isToken, setIsToken] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [isPoliclinicLoading, setIsPoliclinicLoading] = useState(true);
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [localLocation, setLocalLocation] = useState([])

    useEffect(() => {
        const getToken = async () => {
            try {
                const res = await axios({
                    method: "POST",
                    url: "/token",
                    data: {
                        email: 'test@gmail.com',
                        password: 'admin',
                    },
                });
                if (res.status === 200 || res.status === 201) {
                    Cookies.set("regionToken", res.data.token);
                    setIsToken(res.data.token)
                    getAxios.defaults.headers.Authorization = `Bearer ${res.data.token}`;
                }
            } catch (err) {
                navigate('/no-data')
            }
        };
        getToken()
        // o'zgartirildi
    }, [navigate]);

    useEffect(() => {
        async function loadUserFromCookies() {
            const token = Cookies.get("regionToken");
            if (token) {
                getAxios.defaults.headers.Authorization = `Bearer ${token}`;
                setIsToken(token)
            }
        }
        loadUserFromCookies();
    }, [isToken]);

    useEffect(() => {
        if (location.pathname.split('/').length === 2) {
            setThisRouteRegion(location.pathname.split('/')[1])
        } else if (location.pathname.split('/').length > 2) {
            setThisRouteRegion(location.pathname.split('/')[1])
            setThisRouteDistrict(`${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}`)
        }
    }, [location.pathname])

    useEffect(() => {
        let thisDistName = thisRouteDistrict.split('/')[1]
        if (zones.length > 0 && thisDistName) {
            zones.map(region => {
                region.districts.map(district => {
                    if (district.name_uz.replace("'", '').replace("‘", '').replace('’', '').replace("'", '').replace("‘", '').replace('’', '').replace("'", '').replace("‘", '').replace('’', '').toLowerCase() === `${thisDistName.split('-')[0]} ${thisDistName.split('-')[1]}`) {
                        setDistrictID(district.id)
                        setIsLoading(false)
                    }
                })
            })
        }
    }, [thisRouteDistrict, zones, location.pathname])

    useEffect(() => {
        async function getRegions() {
            try {
                setIsLoading(true)
                const data = await getAxios.get(`/zones`);
                setZones(data.data)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(true)
                navigate('/no-data')
            }
        };
        getRegions();
    }, [isToken]);

    useEffect(() => {
        const getPoliclinic = async () => {
            try {
                setIsPoliclinicLoading(true)
                const data = await getAxios.get(`/polyclinics/${districtID}`);
                setDistrictHospitals(data?.data)
                setIsPoliclinicLoading(false)
            } catch (error) {
                setIsPoliclinicLoading(true)
                navigate('/no-data')
            }
        };
        getPoliclinic()
    }, [districtID])

    useEffect(() => {
        localStorage.setItem('thisRouteRegion', thisRouteRegion)
        localStorage.getItem('thisRouteRegion') && setThisRouteRegion(localStorage.getItem('thisRouteRegion'))
    }, [thisRouteDistrict])
    useEffect(() => {
        localStorage.getItem('lang') && setLang(localStorage.getItem('lang'))
    }, [])

    useEffect(() => {
        setLocalLocation(JSON.parse(localStorage.getItem('localLocation'))) || setLocalLocation(location.pathname.split('/').filter((i) => i))
    }, [location.pathname])

    useEffect(() => {
        const pathSnippets = location.pathname.split('/').filter((i) => i);
        localStorage.setItem('localLocation', JSON.stringify(pathSnippets))
        const extraBreadcrumbItems = localLocation.map((pathElem, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            return {
                key: Math.random(),
                // title: <Link to={url}>{pathElem.trim()}</Link>,
                title: <Link to={url}>
                    {
                        zones.map(region => {
                            if (region.name_uz.replace('‘', '').replace('’', '').replace("'", '').replace(' ', '-').toLowerCase() === pathElem.trim()) {
                                return region[`name_${lang}`]
                            }
                            if (region.name_uz.replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '').replace(' ', '-').toLowerCase() === location.pathname.split('/')[1]) {
                                return region.districts?.map(district => {
                                    if (district.name_uz.replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '').replace(' ', '-').toLowerCase() === pathElem.trim()) {
                                        return district[`name_${lang}`]
                                    }
                                })
                            }
                        })
                    }
                </Link>,
            };
        });
        setBreadcrumbItems([
            {
                title: <Link to="/">{langWords[`breadcrumbText_${lang}`]}</Link>,
                key: 'home',
            },
            ...extraBreadcrumbItems
        ])
    }, [location.pathname, lang, localLocation, isLoading, zones])

    return (
        <HospitalContext.Provider value={{
            thisRouteRegion,
            thisRouteDistrict,
            showColNumbers,
            breadcrumbItems,
            setShowColNumbers,
            setChangedThisHospital,
            changedThisHospital,
            zones,
            setLang,
            lang,
            districtID,
            setDistrictID,
            districtHospitals,
            isLoaded,
            loadError,
            isLoading,
            isPoliclinicLoading,
            isToken
        }}>
            {children}
        </HospitalContext.Provider>
    )
}

export const useHospital = () => useContext(HospitalContext);