import { CaretUpOutlined, EnvironmentOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHospital } from '../../context/Context';
import { Spin } from 'antd';
import { memo } from 'react';

function MyMenu() {
    const { zones, lang, setDistrictID, thisRouteRegion, setShowColNumbers } = useHospital()
    return (
        <>
            {
                zones.length > 0
                    ?
                    <ul>
                        {
                            zones.map((region, index) => {
                                const key = String(index + 1);
                                const thisDistrict = region.districts.filter(district => district.region_id === region.id)
                                const regionName = region[`name_uz`].split(' ')
                                const q = regionName.join('-').toLowerCase().replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '')
                                return <li
                                    key={key}
                                    className={`my-menu-list ${q === thisRouteRegion ? 'active' : ''}`}
                                >
                                    <Link
                                        to={`/${q}`}
                                        className='my-menu-link'
                                        onClick={() => setShowColNumbers(true)}
                                    >
                                        <EnvironmentOutlined style={{ marginRight: '4px', fontSize: '15px' }} />
                                        {region[`name_${lang}`].slice(0, 20)}
                                        {region[`name_${lang}`].length > 20 ? "..." : ''}
                                        <CaretUpOutlined style={{ marginLeft: 'auto' }} />
                                    </Link>
                                    <ul className='my-submenu-list'>
                                        {
                                            thisDistrict.map((district, j) => {
                                                const districtName = district[`name_uz`].split(' ')
                                                const w = districtName.join('-').toLowerCase().replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '').replace('‘', '').replace('’', '').replace("'", '')
                                                const subKey = index * 104 + j + 100;
                                                return <li key={subKey}>
                                                    <Link
                                                        className='my-submenu-link'
                                                        to={`/${q}/${w}`}
                                                        style={{
                                                            display: 'block'
                                                        }}
                                                        onClick={() => {
                                                            setShowColNumbers(false)
                                                            setDistrictID(district.district_id)
                                                        }}
                                                    >
                                                        <CaretRightOutlined />
                                                        {district[`name_${lang}`]}
                                                    </Link>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </li>
                            })
                        }
                    </ul>
                    :
                    <div className='spin-parent'>
                        <Spin size='default' />
                    </div>
            }
        </>

    )
}

export default memo(MyMenu)
