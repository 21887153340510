export const langWords = {
    // breadrumb home
    breadcrumbText_uz: "Bosh sahifa",
    breadcrumbText_ru: "Главная",
    breadcrumbText_en: "Home",
    // company name
    companyName_uz: "Davlat tibbiy sug'urtasi jamg'armasi",
    companyName_ru: "Государственный фонд медицинского страхования",
    companyName_en: "State health insurance fund",
    // population text
    peopleNum_uz: "Aholi soni",
    peopleNum_ru: "Население",
    peopleNum_en: "Population",
    // drawer address
    addressText_uz: "Manzil",
    addressText_ru: "Адрес",
    addressText_en: "Address",
    // drawer working time
    workingTimeText_uz: "Ish vaqti",
    workingTimeText_ru: "Рабочее время",
    workingTimeText_en: "Working time",
    // drawer section text
    sectionsText_uz: "Mutaxassislar",
    sectionsText_ru: "Специалисты",
    sectionsText_en: "Specialists",
    // drawer reception text
    receptionText_uz: "Qabulxona",
    receptionText_ru: "Прием",
    receptionText_en: "Reception",
    // marshrut text
    marshrutText_uz: "Marshrut",
    marshrutText_en: "Marshrut",
    marshrutText_ru: "Маршрут",
    // Aydar kol
    aydarText_uz: "Aydar ko'li",
    aydarText_ru: "Озеро Айдар",
    aydarText_en: "Lake Aydar",
    // Aral sea
    aralText_uz: "Orol dengizi",
    aralText_ru: "Аральское море",
    aralText_en: "Aral sea",
}