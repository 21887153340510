import { Button, Empty } from 'antd'
import { langWords } from '../context/LangWords'
import { useHospital } from '../context/Context'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {
    const { lang } = useHospital()
    const navigate = useNavigate()
    return (
        <div className='spin-parent' style={{ flexDirection: 'column' }}>
            <Empty
                imageStyle={{
                    height: 120
                }}
                description="Nimadir muammo yuz berdi!"
            />
            <Button
                onClick={() => navigate('/')}
                type="primary"
                style={{
                    marginTop: 20
                }}
            >
                {langWords[`breadcrumbText_${lang}`]}
            </Button>
        </div>
    )
}

export default ErrorPage
