import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"
import { langWords } from "../../context/LangWords"

function QoraqalpogistonMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[5][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/qongirot-tumani">
                                        <path d="M382.38,384.62s-7.13,3.63-9.26,6-2.62,7.26-2.62,7.26-8-.88-9.88-2.63-6.74,0-6.74,0,1.24,6.37-1.26,8.21a2.89,2.89,0,0,1-2,.5c-2.4-.15-5.24-2.36-6-2.84-1-.62-6.74-.87-6.74-.87a17.78,17.78,0,0,1-1.5,4.75c-1,1.75-4.41,1.66-4.41,1.66l-2.22-2.41-1.5,4s3,1.75,2.5,4.25-3.5-1-4.75.5,2.5,2.25,1.25,7-5,1.75-5,1.75a41.67,41.67,0,0,1-6.75.5c-4,0-13-4-17.5-4.75s-10.25,5.25-10.25,5.25l-3.5,2v3.75a56.59,56.59,0,0,1,7.25,5.25c2,2,6.5,1.25,6.5,1.25s3.75,5.75,4.25,10.25,6.75,11,6.75,11l1,4s10.75,5.5,9.5,8.75-5.75.25-5.75.25-3.75,1.5-5.25.75-.75-10.75-.75-10.75l-6-9.5-11.5-2.5L282,450.5s-3.25-3-4.25-3,0-4-3-5.5-8,2-8,2l-1,6.75s-2.5-4.5-5.5-1.75,2.5,5.75,3.5,8.5-.25,4.75-.75,7.5.75,9.5-1.25,12-7-.75-7-.75l-1.5,3.5S256.75,484,255,486s-1.75-.75-8-.75S240,488,235.5,489s-10.75-8.5-29,0-15,18.25-18.25,24.5-7.75,4-13.25,7S169.12,551,171.75,560c5.5,6.5,2.25,11.5,2.25,11.5l1.75,13L181,592c3.25,0,4.5-1.75,6.5-1.5s.5,4.5-2,5.5-7.75,3-7.75,7-2.08,4-2.08,4-99.34-8.33-100.34-7.67,1-488.9,1-488.9L323.67,35c.35.31.76.65,1.23,1,0,0-3,3.87-3,7.24s-.26,7.63-1.13,10.13-5.75,8.74-8.13,12.5-5.5,9.12-6.87,15-2.75,6.87-2.63,9.87.86,4.25,0,5.5-2.62-.37-3.24,1.5-5.26,12.37-5.38,13.63.38,2.74,0,3.74-1.25-.87-2.12,2.13-3.76,10.13-3.63,15.75.75,9.5,2.63,13.25,4.74,8.5,5.5,12.13a13.26,13.26,0,0,1,.24,5.5h-2l-4.87,8.74s-1,9.13,0,10.88a32.65,32.65,0,0,1,2.5,10c0,2.25-1,10.75,0,13.5s7.5,11,7.5,15.25v21.5c0,3,4.75,8.5,4.75,11.25v5.75s-5.5-3.75-6.25,5.5.5,9.75,2,12.75L310,297.5s8.75-2.25,8,6c0,0-7.5,1.25-10.5,2.75s-8.75,4-10.25,10.25a95,95,0,0,0-2,11s4.75,6.25,24.5,8,25.25,0,25.25,0,2.75,7,6.75,7.5,10.75-1.75,10.75-1.75l1.75,1.82h7s2.5,1.05,2.5,5v6.06h5.78c.52,1.41,1,2.74,1.35,3.76C382,361.25,382.38,384.62,382.38,384.62Z" data-name="Қўнғирот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[6][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/moynoq-tumani">
                                        <path d="M572.88,232.12c0,4-.13,10.5-1.76,14.26s-2,8.12-2.12,13-.38,11.37-2.25,12.87-5.75.75-7,3,.5,6.87-1.13,8.75-5.5,1.5-5.5,1.5-.62,6.12-1.62,7.75-3.75,2.75-4.75,5.5-1.63,4.75-1.75,5.13a10.38,10.38,0,0,1-6.11,2.87c-2.89.13-28.51-.63-28.51-.63s-4.13-2.12-6.26,0-4.37,6.13-6.12,6.63-6.38,0-6.38,0l-4-1.25-11.74,2.88s-3.63,7.24-6.63,7.62-9-1.12-9-1.12l-4.37,4.62L449,328.62s-.62-5-2.25-4.62-1.5,3.62-1.5,3.62l-3.5-.87-1,1.87-2.63.87v4.13c-3.53-3.75-5.78-2.17-7.77-1.17a7.39,7.39,0,0,1-1,.43c-2.26.74-8.28.74-8.13,0,1.13-5.5-2-6.38-2-6.38s-3-.25-6.54-.88-8.09-1-11,0a46.33,46.33,0,0,1-8.25,1.5s-.38,1.88-1.5,6.5a7.9,7.9,0,0,1-5,5.76V343c-4-.5-8.88.88-9.5,2.75-.43,1.31.84,5.07,2,8.37h-5.78v-6.06c0-3.94-2.5-5-2.5-5h-7l-1.75-1.82s-6.75,2.25-10.75,1.75-6.75-7.5-6.75-7.5-5.5,1.75-25.25,0-24.5-8-24.5-8a95,95,0,0,1,2-11c1.5-6.25,7.25-8.75,10.25-10.25S318,303.5,318,303.5c.75-8.25-8-6-8-6L300.75,279c-1.5-3-2.75-3.5-2-12.75s6.25-5.5,6.25-5.5V255c0-2.75-4.75-8.25-4.75-11.25v-21.5c0-4.25-6.5-12.5-7.5-15.25s0-11.25,0-13.5a32.65,32.65,0,0,0-2.5-10c-1-1.75,0-10.88,0-10.88s7.5,3.26,10.37,5a51.15,51.15,0,0,0,6.2,3.13h8.56l9.52-7.87s-1.4-14.26-1.65-19-3-15.26-1.37-21.13,7.5-10.37,7.5-10.37l.24-4.63s-2.62-1-2.74-4.75-.5-18.75-.26-19.12,5.13-3.63,5.13-3.63l.63-11.5,1.37-2.25V70.75L336,68.5l.75-3.25,1.63-.37s-.5-6.76,0-7.13,4.24-4.13,4.24-4.13V45.85a197.51,197.51,0,0,0,21.71,8.82c14.34,4.66,34.5,15.83,39.34,20.33s107.66,90.67,114.16,95.5,34,26.17,37.17,30.5c1.94,2.65,12.4,10.59,20.25,16.37C575.43,217.5,572.88,228.12,572.88,232.12Z" data-name="Мўйноқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[13][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/shumanay-tumani">
                                        <path d="M384.44,440.69a12.86,12.86,0,0,0-2.56-2.07c-2-.87-5.38,2.5-8.26,2.88s-10.5-2.88-13.37-3.75S348.75,425,345,420.88c-2.72-3-9.5-10.38-13-14.22,0,0,3.41.09,4.41-1.66a17.78,17.78,0,0,0,1.5-4.75s5.74.25,6.74.87c.77.48,3.61,2.69,6,2.84,0,0,3.25,7.92,3.49,8.29s5.5-.25,7.63.63S372,418.62,372,418.62v7.76l4.5,2.24V431s1,0,2.38.21c2.38.32,5.82,1.08,6.62,2.91C386.71,436.9,384.59,440.44,384.44,440.69Z" data-name="Шуманай тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[9][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/taxtakopir-tumani">
                                        <path d="M714.5,332.83s-17.72,29.09-33.13,54.49l-4.49-6.07-48.13-20.13-5.24,9.63-30.39-16.63-36.74,8.32s-25.26,41.81-25.5,47.44,2.87,25.24,3.5,28.24,4.62,10,4.62,10-.14,4.87-.1,9.26L490.5,428s-3.62-7.75-.88-15.25,13.76-14.13,13.76-14.13-1.38-4-7.38-3.87l-3.62-9.87-3.79-1L485.12,388l-5.24-6.12-1-14.5s-3-2-3-10.63,1.37-12.13,1.37-12.13l-.5-6.7,3.37-5.42,4-3-1.37-6.38,4.37-5.24.5-6.38,4,1.25s4.63.5,6.38,0,4-4.5,6.12-6.63,6.26,0,6.26,0,25.62.76,28.51.63a10.38,10.38,0,0,0,6.11-2.87c.12-.38.75-2.38,1.75-5.13s3.75-3.87,4.75-5.5,1.62-7.75,1.62-7.75,3.88.38,5.5-1.5-.12-6.5,1.13-8.75,5.13-1.5,7-3,2.13-8,2.25-12.87.5-9.26,2.12-13,1.76-10.26,1.76-14.26,2.55-14.62,2.37-14.75c5,3.66,8.92,6.46,8.92,6.46L656.83,312l27-4.33,9.34,15.66Z" data-name="Тахтакўпир тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[0][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/amudaryo-tumani">
                                        <path d="M520.6,498.82c-1.07,1.61-1.72,2.8-1.72,2.8l-3.48,7.47a3.4,3.4,0,0,0-1.57-2.09c-1.83-1.33-3.83,1.67-7,1.67s-3-4.67-4.83-4.84-.17,1.5-.83,2.84-2-.5-3.67-.17.67,1.5-.33,3.67-1.67.33-3.34.83-.66,2.17-2.83,2.5-1-3-3-3.33-1.87,2.83-2.21,4.66,4.34,3.5,1,6.84c-1.5,1-1.16-1.5-3-2.17s-8,5.67-8,5.67c-1.75-2.5-7.45-1.79-10.95-6.92a4.55,4.55,0,0,0-4.5-2.75c-1.63,0-3-2.25-3.26-4.75s6.26-.37,7.76-2.37-2.38-6-2.38-6l.12-5.63s-.5-.63-2.37-3.13-.5-4.87,2.37-4.74,4,0,4.5-3-4.74-4.88-7.62-6a5.84,5.84,0,0,1-.76-.36s-.62-8.14,3.51-9.64,10,1.57,17.52-.55c4.92,2.44,12,3.75,16.73,6.79,5.25,3.38,7.62,15.13,10.12,19.5S520.6,498.82,520.6,498.82Z" data-name="Амударё тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[7][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/nukus-tumani">
                                        <path d="M446.5,442.88c.5,3.74-5.25,10.74-5.25,10.74-6.63,1-9-.24-9-.18s-3.63-6.69-3.63-6.69-4,5.13-6.62,3-.88-6.5-3.12-8-11.88,0-11.88,0L405.38,436H401.5l-1.62-2.38H397l-.74-2.83a4.28,4.28,0,0,0,1.62-2.17c1.5-3.74-2.63-8-2.38-14.5s-6.79-11.62-7-15.62a27.61,27.61,0,0,0-.66-5.75l2.37,1.13,3.87,6.74a38.57,38.57,0,0,1,4.08.88c.46.25,3.92,2.46,3.92,2.46a2.66,2.66,0,0,1,3.38-1.08c2.12,1,2.25,5.37,2.25,5.37a5.57,5.57,0,0,0,5-2.37l1.75,4.87H418c7.75,9,12.25,22,12.25,22a54.61,54.61,0,0,1,9.75-6.5c5.25-2.63,6.53,1.75,6.53,1.75s-4.78,2.25-5.28,4.5S446,439.12,446.5,442.88Z" data-name="Нукус тумани" fill="transparent" stroke="#fff" strokeWidth="2">

                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[11][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/xojayli-tumani">
                                        <path d="M479.77,469.33c-7.5,2.12-13.39-.95-17.52.55s-3.51,9.64-3.51,9.64a12.63,12.63,0,0,1-5.24-6.14c-.88-2.26-7.62-4.26-7.62-4.26a54.29,54.29,0,0,1-3.26,4.63c-.5.37-6.62-2.63-6.62-2.63l-30.12,1.63s-2.63-3.75-3-3.75-11-11.75-11-11.75a18,18,0,0,0,1.12-5.13c.09-2-5.13-8.13-8.56-11.43.15-.25,2.27-3.79,1.06-6.57-.8-1.83-4.24-2.59-6.62-2.91l.87-3.71,5.37-2.62s3.38,4.12,5.88,5.24c1.77.8,3.73,1.6,5.26.67l.74,2.83h2.88L401.5,436h3.88l1.62,5.75s9.62-1.5,11.88,0,.5,5.87,3.12,8,6.62-3,6.62-3,3.6,6.69,3.63,6.69,2.37,1.18,9,.18,9,2.38,13.25,3.5,6,1.88,10.5,5,8.38,3.38,13.25,6.38C478.73,468.79,479.24,469.07,479.77,469.33Z" data-name="Хўжайли тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[1][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/beruniy-tumani">
                                        <path d="M622,453.12c1.12,1.63,1.25,6.63,1.5,12.63s-5.62,14-12.75,21.13-5.37,7.74-6.5,9.5-29.5.62-33,1.62-5,9-5,9-1.87-3.5-4.5-3.88-6.87,6.88-6.87,6.88-5-.38-6.38,0-1.75,4.88,0,7.5,1.25,1.75,6.88,2.38,2.87,6.12.37,9.5-1.63,8,0,9.5,3.63-3.13,5.25-2.76-.5,4,0,5.88,4.26,7.38,4.26,7.38a4.89,4.89,0,0,0-.51,1.87c-.25,4.75,8.75,15,9,17a24.06,24.06,0,0,1,0,4.5s-3.25.25-4.5,1.5a51.73,51.73,0,0,0-3.54,4.43,8.58,8.58,0,0,0-1.54-3c-1.17-1.34-2.34,1.5-4,1.5s-1.5-2.34-4.17-4.67-4.83-1.67-6.5-2.67-.17-3.5-2.67-7.16-5.16-.84-6.83-2.34.83-4.83,0-6.5-2.67,0-5.67-2,0-4.33-2.83-9.5-8.12-1.21-9.29-4,.46-2.62.62-5-.66-1.83-2.16-2.5-1-2-3-5.33-.34-4.33-1-6.67a20.64,20.64,0,0,1-1-6.33,24.49,24.49,0,0,0-.27-3.41l3.48-7.47s.65-1.19,1.72-2.8a32.5,32.5,0,0,1,4.15-5.07c3.37-3.25,2.87-5.37,4.5-10.25s6.37-6.5,8.75-10,1.25-7.5,1-12.12c-.06-1.15-.09-2.55-.1-4,0-4.39.1-9.26.1-9.26s-4-7-4.62-10-3.76-22.62-3.5-28.24,25.5-47.44,25.5-47.44l36.74-8.32,30.39,16.63c.13,3.13-5.63,9-7.13,11.75s.62,5.5-.76,8.25-3.87,7.87-.87,16.37.13,21.26,2,27.38S619,451.38,619,451.38A4.28,4.28,0,0,1,622,453.12Z" data-name="Беруний тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[14][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/ellikqala-tumani">
                                        <path d="M654.5,472.17l7.17,18.66a5.11,5.11,0,0,1-3.92,2.67c-2.75.25-8.75-2-11.5,0s-6.75,7.25-11.75,7.75-11-3-15.25-1.75S612,505,597,502.75c0,0,3.5,5.25,0,6.5s-16.5-2.75-16.5-2.75.75,7.5,0,10.75-5,9.5-5,9.5,2,10.75,0,13.25c-1.73,2.16-8.33,5.45-10.24,9.38,0,0-3.76-5.5-4.26-7.38s1.62-5.5,0-5.88-3.62,4.26-5.25,2.76-2.5-6.13,0-9.5,5.25-8.88-.37-9.5-5.13.24-6.88-2.38-1.38-7.12,0-7.5,6.38,0,6.38,0,4.24-7.25,6.87-6.88,4.5,3.88,4.5,3.88,1.5-8,5-9,31.87.12,33-1.62-.63-2.38,6.5-9.5,13-15.13,12.75-21.13-.38-11-1.5-12.63a4.28,4.28,0,0,0-3-1.74s-.38-10.76-2.25-16.88,1-18.88-2-27.38-.5-13.62.87-16.37-.74-5.5.76-8.25,7.26-8.62,7.13-11.75l5.24-9.63,48.13,20.13,4.49,6.07c-11.83,19.49-22.3,36.82-22.37,37.18C653,442.83,654.5,472.17,654.5,472.17Z" data-name="Элликқалъа тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[10][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/tortkol-tumani">
                                        <path d="M701.5,627.5l-27.67,13.33c-.66.34-34.66-41-34.66-41l-5.5-3.83-28.5-2-7.34,5.33a66.23,66.23,0,0,1-2,7.5,4.12,4.12,0,0,0,1.34,4.5,1.92,1.92,0,0,1,0,2.84c-1.5,1.33-3.5-3.17-5.5-3.67a6.33,6.33,0,0,1-4.67-4.67c-.5-2.5,1.5-5.16,1.17-8.33s-3.5-2.67-6.67-3.5-3.67-2.5-6-4.83-2.5,0-4.33-.17-4.34-6.33-4.84-8.17c-.19-.71-.39-1.44-.62-2.15a51.73,51.73,0,0,1,3.54-4.43c1.25-1.25,4.5-1.5,4.5-1.5a24.06,24.06,0,0,0,0-4.5c-.25-2-9.25-12.25-9-17a4.89,4.89,0,0,1,.51-1.87c1.91-3.93,8.51-7.22,10.24-9.38,2-2.5,0-13.25,0-13.25s4.25-6.25,5-9.5,0-10.75,0-10.75,13,4,16.5,2.75,0-6.5,0-6.5c15,2.25,18-2,22.25-3.25s10.25,2.25,15.25,1.75,9-5.75,11.75-7.75,8.75.25,11.5,0a5.11,5.11,0,0,0,3.92-2.67l4.16,7s-30.16,16-30.83,16.5,9.5,19.34,11,23,18,26.5,20.17,30.17Z" data-name="Тўрткўл тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[4][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/qoraozak-tumani">
                                        <path d="M538,473.5c-2.38,3.5-7.12,5.12-8.75,10s-1.13,7-4.5,10.25a32.5,32.5,0,0,0-4.15,5.07s-11.48,1.18-14-3.2-4.87-16.12-10.12-19.5c-4.74-3-11.81-4.35-16.73-6.79-.53-.26-1-.54-1.52-.83-4.87-3-8.75-3.25-13.25-6.38s-6.25-3.87-10.5-5-6.62-4.5-13.25-3.5c0,0,5.75-7,5.25-10.74s-5.75-8.13-5.25-10.38,5.28-4.5,5.28-4.5l12.18-1.75s4.41-9.75,5.67-12.37-1.13-6.63-1.13-6.63L457,409.12s0-8.24-4.38-11c0,0,1.38-1.37,3.13-6.37s-.75-8.63-1.31-12,2.44-2.87,5.06-3,2-4.13,1.25-6.5-5-1-7-1.5-3.87-3.5-4.87-5.75-.88-20.62-.88-20.62l-9.88-8.76v-4.13l2.63-.87,1-1.87,3.5.87s-.13-3.24,1.5-3.62,2.25,4.62,2.25,4.62l6.88-3.12,4.37-4.62s6,1.5,9,1.12,6.63-7.62,6.63-7.62l11.74-2.88-.5,6.38-4.37,5.24,1.37,6.38-4,3-3.37,5.42.5,6.7s-1.37,3.5-1.37,12.13,3,10.63,3,10.63l1,14.5,5.24,6.12,3.47-4.12,3.79,1,3.62,9.87c6-.13,7.38,3.87,7.38,3.87s-11,6.63-13.76,14.13.88,15.25.88,15.25l48.4,29.38c0,1.45,0,2.85.1,4C539.25,466,540.38,470,538,473.5Z" data-name="Қораўзак тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[12][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/chimboy-tumani">
                                        <path d="M459.5,376.75c-2.62.13-5.62-.37-5.06,3s3.06,7,1.31,12-3.13,6.37-3.13,6.37c-4.37-2.74-12.37,4.63-12.37,4.63h-4.5a8.54,8.54,0,0,0-3.25-6c-3.25-2.75-8,.63-10-2s-1.26-10.87-1.26-10.87-8-6.5-9.63-11.88,2.76-6.38,3.5-7.25.13-3.25.13-3.25a38.11,38.11,0,0,0,3.87,0c1.5-.12,4.63-2.5,4.63-2.5a4.1,4.1,0,0,0,3.13,1.38,42.4,42.4,0,0,1,4.3,0,10.62,10.62,0,0,1-2.06-4.88c-.12-2.38,2.76-4.5,2.88-4.88a15.06,15.06,0,0,0-.82-3s-2.18-.74-2.56-4,5.13-3.5,5.13-3.5l-2.87-3.62,1.74-.62-2.27-3.43c2-1,4.24-2.58,7.77,1.17l9.88,8.76s-.12,18.37.88,20.62,2.87,5.25,4.87,5.75,6.25-.87,7,1.5S462.12,376.62,459.5,376.75Z" data-name="Чимбой тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[2][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/kegeyli-tumani">
                                        <path d="M464.38,413.88c-1.26,2.62-5.67,12.37-5.67,12.37L446.53,428s-1.28-4.38-6.53-1.75a54.61,54.61,0,0,0-9.75,6.5s-4.5-13-12.25-22h-3.5l-1.75-4.87a5.57,5.57,0,0,1-5,2.37s-.13-4.37-2.25-5.37a2.66,2.66,0,0,0-3.38,1.08s-3.46-2.21-3.92-2.46a38.57,38.57,0,0,0-4.08-.88l-3.87-6.74-2.37-1.13s-5.26-3.63-5.5-8.13c0,0-.38-23.37-1.5-26.74-.34-1-.83-2.35-1.35-3.76-1.19-3.3-2.46-7.06-2-8.37.62-1.87,5.5-3.25,9.5-2.75v-3.62a7.9,7.9,0,0,0,5-5.76c1.12-4.62,1.5-6.5,1.5-6.5a46.33,46.33,0,0,0,8.25-1.5c2.87-1,7.41-.62,11,0s6.54.88,6.54.88,3.13.88,2,6.38c-.15.74,5.87.74,8.13,0a7.39,7.39,0,0,0,1-.43l2.27,3.43-1.74.62,2.87,3.62s-5.5.26-5.13,3.5,2.56,4,2.56,4a15.06,15.06,0,0,1,.82,3c-.12.38-3,2.5-2.88,4.88a10.62,10.62,0,0,0,2.06,4.88,42.4,42.4,0,0,0-4.3,0,4.1,4.1,0,0,1-3.13-1.38s-3.13,2.38-4.63,2.5a38.11,38.11,0,0,1-3.87,0s.63,2.38-.13,3.25-5.12,1.87-3.5,7.25,9.63,11.88,9.63,11.88-.73,8.24,1.26,10.87,6.74-.75,10,2a8.54,8.54,0,0,1,3.25,6h4.5s8-7.37,12.37-4.63,4.38,11,4.38,11l6.25-1.87S465.62,411.25,464.38,413.88Z" data-name="Кегейли тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[0]?.districts[3][`name_${lang}`]}>
                                    <Link to="/qoraqalpogiston-respublikasi/qonlikol-tumani">
                                        <path d="M397.88,428.62a4.28,4.28,0,0,1-1.62,2.17c-1.53.93-3.49.13-5.26-.67-2.5-1.12-5.88-5.24-5.88-5.24l-5.37,2.62-.87,3.71c-1.36-.18-2.38-.21-2.38-.21v-2.38l-4.5-2.24v-7.76s-8.12-4.87-10.25-5.74-7.37-.26-7.63-.63-3.49-8.29-3.49-8.29a2.89,2.89,0,0,0,2-.5c2.5-1.84,1.26-8.21,1.26-8.21s4.87-1.75,6.74,0,9.88,2.63,9.88,2.63.5-4.88,2.62-7.26,9.26-6,9.26-6c.24,4.5,5.5,8.13,5.5,8.13a27.61,27.61,0,0,1,.66,5.75c.17,4,7.21,9.12,7,15.62S399.38,424.88,397.88,428.62Z" data-name="Қанликўл тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                        <path className="st2" d="M675.7,448.3L675.7,448.3" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default QoraqalpogistonMap;