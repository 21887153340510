import axios from "axios";

export default axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
        Accept: "application/json",
    },
});

export const getAxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`
    },

});
