import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import './assets/nunito_fonts/fonts.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HospitalProvider } from './context/Context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <HospitalProvider>
      <App />
    </HospitalProvider>
  </BrowserRouter>
);