import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function NamanganMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[5][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/pop-tumani">
                                        <path d="M408.83,424.17l-1.35,6.33s-7.73,2.5-10.73,3.75-11.25,5.5-16.25,5S377,443,375,444s-8.5-1.25-8.5-1.25h-9.25l-3.25,8.5-25-1.5,3.25,6.25-21.42,38.33-3.33-1.83-5,.5-3.17,4.5s-5.5,3.33-8.66,4.33-19.34.34-19.34.34l-5.83,4.16a53.68,53.68,0,0,0,0-6.66c-.17-5.5-8.33-7.5-16.5-8.17s-17,12.83-17,12.83l-8.67,3.34-29.5,2.5c1.67-11.34-14-15.5-14-15.5l-27.16-1.5-12.17-5-2.67-5.5s5.5-20.67,5.5-21.17,4,1,4,1l6.17,14.83c9,5.17,9.83-2.16,8.67-6.16s-6.67-7.84-7.5-10.34-7.84-20.5-9.34-23-9-5.5-9.66-6.16-2-7-3.5-11.5-30.34-41.34-32.5-43.34-5.34-10.33-9.5-13.5-9-9-12.67-13.33-9.5-9.83-9.5-9.83L75.83,318l7-4.83-.66-4.34s-7.67-4.5-12.34-9.33-15.5-16.33-15.5-16.33l6.34-3.67s-4.5-8.83-7-11.17-5.34-12.5-6.17-19,9-20.16,9-20.16l-1.5-8.5-19.67,1L35.67,198l2.83-3.17A22.18,22.18,0,0,0,46,195c3.5-.67,16.67-11.83,22.5-16.33s8.83-14.84,10.5-19.84-.83-13.16-.83-13.16l10.16.16s13-10.33,15.67-13.5,4.33-7.66,13.5-13.33,21.33-2.67,21.33-2.67,10.84-8.33,11.5-8.33,14.34,4.17,14.34,4.17L168,122.33s15.17,23,18.67,28.84S192,166.83,194,174.33s-4.46,19.17-4.46,19.17,0,10.67,0,17.67,7.29,10.33,8.29,15.16-8,16-8,16a43.82,43.82,0,0,0,0,10.17c.67,5.5,6,11.67,6,11.67l7.5,3a11.1,11.1,0,0,0,6-6.34c2.34-3.66,8.84-23.33,15.67-25.5s13.83,12.17,13.83,12.17,12,9.5,14.17,11.17,5.33,11.83,5.33,11.83,5.34,1.67,5.84,1.67S286.67,256,286.67,256l6.16.5L290.75,279l-9.25-1.5-9.25,22s-11.5-.25-14.25,1.25-8,10-8,10l8.75,13.46L263.5,323l7.92,10.75v9.75l-3.17,5.25,5.75,29.5,8.5,7.75,9-5.75,3.75,8,10,5L304,400.5l10.25-4.25,1.25,6.25,3,3,2.25-6.25S347.5,385.25,355,389s8.5,23.25,8.5,23.25,8.5,8.5,10,8.5,31-.42,31-.42Z" data-name="Поп тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[10][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/chust-tumani">
                                        <path d="M444.17,387.67c-4.5,5.66-11.84,1.5-11.84,1.5l-8.83,4.66s-4.17,6-4.17,6.67v11.83l-7.66,6-7.17,2s-29.5.42-31,.42-10-8.5-10-8.5-1-19.5-8.5-23.25-34.25,10.25-34.25,10.25l-2.25,6.25-3-3-1.25-6.25L304,400.5l1.25-7.25-10-5-3.75-8-9,5.75-8.5-7.75-5.75-29.5,3.17-5.25v-9.75L263.5,323l-4.75,1.25L250,310.79s5.25-8.54,8-10,14.25-1.25,14.25-1.25l9.25-22,9.25,1.5,2.08-22.5c5.84,6.67,17,8.5,17,8.5l8.34,11.83s6.5,2,11,3.84,8-1.34,12.33-7.17,9.83-4.33,9.83-4.33c5.17-12.17,9.84-8.67,9.84-8.67l4,9.33a4.15,4.15,0,0,0,5-1.66s-1.84-6.84,2.33-10.17,11.33.67,14.17,4.5,3.16,6.83,5,16.83-4.5,20.84-.84,23.84c7,3.83,14.5-6.34,17.67-12.34s.83-16.16,9.83-19.83,15.17,7.67,17.34,12.33,2,16.84,2,16.84l1.73,1.94v0h0c0,.79-6.72,16.3-5.74,22.69s6.66,9,7.66,16.84c.42,3.27.6,9.05.67,14.67.11,7.82,0,15.33,0,15.33S448.67,382,444.17,387.67Z" data-name="Чуст тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[1][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/mingbuloq-tumani">
                                        <path d="M550.52,430.81a30.38,30.38,0,0,1-3.69,4.36c-2.5,2.83-21.66,8.16-22.33,8.33s-7.83,8.17-7.83,8.17-4.84.66-7.67,1.16-7,4.5-7,4.5l4,4.17-17.67,10.17s4.5,4.66,6.5,8.5,5.5,16.83,5.5,16.83-15,5.33-19,6.67-8,5.5-8,5.5l-15.66,2.33s-16,11.33-19.5,13.67-20,5.5-20,5.5l-6.34,8.83-20.16,6.83-9.5,14-17.5-11.83s-3.17-5.17-3.67-5.33-15.33-6.17-18.33-7.84S328,524.67,328,524.67l-16-2.84H298.17s2-9,2.16-9.5,6.5-9.16,6.5-9.16l4-8.84L332.25,456,329,449.75l25,1.5,3.25-8.5h9.25S373,445,375,444s.5-5.25,5.5-4.75,13.25-3.75,16.25-5,10.73-3.75,10.73-3.75l1.35-6.33s5.42-4.17,9.17-3.42a64.57,64.57,0,0,1,9.17,2.25c2.16.83,5.33,4.83,9,4.5s6.66-4.83,12-4.17,6.5,3,10.5,3.17,11,.5,11,.5l6.16,6.17a29.8,29.8,0,0,1,9.84,1.33c5.66,1.67,10.83-3.33,10.83-3.33l12.83,1s2-5.5,7.17-4.5,9.67,3,14.83,1.5S550,430.7,550.52,430.81Z" data-name="Мингбулоқ" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[0][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/kosonsoy-tumani">
                                        <path d="M540.83,293.17c-.16,3.33,0,9.33,0,9.33l-9.5,1.83S527,312.38,527,315.86s-1.67,21.81-1.67,21.81L516,346v7.5a17.63,17.63,0,0,1-11.77,5,18.27,18.27,0,0,0-3.56-2.86S501.83,352,498,347s-8.9-10.83-8.9-10.83h-2.93L483.5,331H480l-2.17-3.33,2.34-5.17-1.34-10.83,2-5.67s-5.83-2-7.5-5.5-7-11.5-9.33-10.5-1,23.21-1.5,25.86-5.33,6.81-5.33,9.64.33,14.83-.67,19.17-4.5,12.27-4.5,13.38,1.17,3.12-1.67,3.62-4.6-7.17-4.6-7.17L442,356.34c-.07-5.62-.25-11.4-.67-14.67-1-7.84-6.66-10.34-7.66-16.84s5.74-21.9,5.74-22.69l.92,1,6.17.33,8.33-9.17V290.5s4.67-9.17,6.17-13.33,2-13.17,2.83-17.34,7.34-14.83,7.67-21.16-9.33-6.67-9.33-6.67a33.6,33.6,0,0,1-1.84-5.67c-.83-3.5,2.34-7.33,2.5-9.66s-2-4.5-2.16-8.34,3.33-7,6.83-10a33,33,0,0,0,6.17-7.83h2.83L483,195l7.33-2c2.67-2.67,1.84-2.83,8-3S509,200,513.5,200.17s4.5-3.34,4.5-3.34l6.17,3s-.67,20.5.66,22.84,6.17,6.66,6.67,9.5-2,5.83,0,8.16,9.5,9.17,10,12.67,3.17,18.67,3.17,19.33S541,289.83,540.83,293.17Z" data-name="Косонсой тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[2][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/namangan-tumani">
                                        <path d="M587.67,406.17s-6,.66-8.34,2.16-3.36,8.72-3.67,8.8l-6.66,1.7s-4,6.67-6,7.67-4.5-2.67-4.5-2.67h-5.83a16.6,16.6,0,0,1-2.14,7h0c-.5-.11-14.12-3.11-19.19-1.64s-9.66-.5-14.83-1.5-7.17,4.5-7.17,4.5l-12.83-1-2.67-6s2.84-6.84,1.34-12.34-7.67-11.33-5.67-17.16,7-11,7-11v-5.34a6.84,6.84,0,0,1,5.67-2.83c3.66.17,6.5,0,6.5,0s2.7-10.55-4.44-18a17.63,17.63,0,0,0,11.77-5V346l9.33-8.33c8.5,2,5.34,9.33,8.84,14.66s10.5,2.34,10.5,2.34c2.61-3.28,14.42-15.07,14.83-15.47a6.89,6.89,0,0,0,1.67,1.47l5,.1c.11.26,3.66,8.77,3.5,11.4s-1,17,0,19.66,7.83,1.34,8.66,3.84,0,6.16,0,6.16l-3.5,3.5s-.09,10.34,3.87,13a23,23,0,0,0,8.3,3.34Z" data-name="Наманган тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[11][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/yangiqorgon-tumani">
                                        <path d="M596.5,239.5l5.06-1v2.17a72.71,72.71,0,0,0-9.73,15c-5,9.83-3.33,22.16-3.33,22.16l-6.17-1.16a17,17,0,0,0-1-5.84c-1.16-3.66-5.83-3.66-5.83-3.66l-.17,4.66L571.5,275s1.17,13,0,17.67-.17,13.33-.17,13.33c9.5,1.83,12,7.67,11.67,14s-16.5,3.17-19.33,3.17-5.34,5.33-5.84,10.33a7.48,7.48,0,0,0,1.67,5.7c-.41.4-12.22,12.19-14.83,15.47,0,0-7,3-10.5-2.34s-.34-12.66-8.84-14.66c0,0,1.67-18.34,1.67-21.81s4.33-11.53,4.33-11.53l9.5-1.83s-.16-6,0-9.33,3.84-20.17,3.84-20.84S542,256.5,541.5,253s-8-10.33-10-12.67.5-5.33,0-8.16-5.33-7.17-6.67-9.5-.66-22.84-.66-22.84l-6.17-3,1.83-7.16,1.84-1.17,1-5.17,5.16-3.16,5-9.67,3.34,2,2.66-.17s1.34-3.83,2.34-7.66,3-7,7.5-10.84a9.47,9.47,0,0,0,3.5-8.66l-6.5-8.84.5-5.33c6-10.67,6.5-14.17,5.5-22.5s-7.67-9.67-12.34-9.83-9,.5-10.16-5.24,8-5.1,12.5-7.6,4.66-3.5,7-9.3,6.33-8.86,11-13S573,61.5,575,65.67s-2.5,6.33-3.33,16c-.84,5.83,1.16,11.83,1.33,17s-7.17,12.83-9.5,15.83-2.67,8.33-1.83,10.83,4.16,10.5,8.16,8.17.5-8.17-.66-9.83-1.34-6,5.33-9.17c8.28-1.84,5.13,8.46,8.48,11.54,0,0-.31,13.29-.15,20.13a21.88,21.88,0,0,0,3.84,11.66l-.17,6-6,2.5-.17,4s6.84,7.17,12,9.84,6.84,5.33,6.84,5.33a15.22,15.22,0,0,0-.67,6c.17,4,3,10.33,5.83,15.5s4,7.83,3,12.67,1.5,9.66,1.5,9.66l-16.33,1.84-1,4.66Z" data-name="Янгиқўрғон тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[9][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/chortoq-tumani">
                                        <path d="M649.67,211.17c-1.84,3.5-6.67,6.83-8.84,9.16s-6,11.17-7.66,14.67-6.17,15.33-5.34,17.83,9,3.67,9,3.67l-.66,7.33L639,268s6.33,8.83,6.33,13.67-8,11.66-7.16,21.33,5.5,8.17,5.66,9.83a46,46,0,0,1,0,5S640.33,316,637,317s-9.17,3.5-10.83,7.33-5,12-12.84,17.34l-47.16-.9-5-.1a6.89,6.89,0,0,1-1.67-1.47,7.48,7.48,0,0,1-1.67-5.7c.5-5,3-10.33,5.84-10.33s19,3.16,19.33-3.17-2.17-12.17-11.67-14c0,0-1-8.67.17-13.33s0-17.67,0-17.67l3.83-3.17.17-4.66s4.67,0,5.83,3.66a17,17,0,0,1,1,5.84l6.17,1.16s-1.67-12.33,3.33-22.16a72.71,72.71,0,0,1,9.73-15V238.5l-5.06,1-5-3.67,1-4.66,16.33-1.84s-2.5-4.83-1.5-9.66-.16-7.5-3-12.67-5.66-11.5-5.83-15.5a15.22,15.22,0,0,1,.67-6s-1.67-2.67-6.84-5.33-12-9.84-12-9.84l.17-4,6-2.5.17-6a21.88,21.88,0,0,1-3.84-11.66c-.16-6.84.15-20.13.15-20.13a2.93,2.93,0,0,0,1,.63c2.33,1,4.33-3.17,4.33-3.17l.84-14.67h4l9.66,19.34a38.05,38.05,0,0,1,7.5,6.66c4,4.5,9,11.5,9.34,14.84s-3.17,11.33-.84,13.5,6.34,0,8.5-.84,2.5-6.83,6.5-7,4.84,5.34,3.67,10.17-.17,7.83,3.5,14.17,11.83,11.83,11.83,11.83h3.34C659.17,195,651.5,207.67,649.67,211.17Z" data-name="Чортоқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[7][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/uychi-tumani">
                                        <path d="M714.21,284.56s-6.38,6.77-8.38,10.77-9.66,10.84-9.66,10.84c3.33,6.83-9.5,21.33-12,23.33S673,337,669.67,339.83s-1.5,6-2.5,8.5-7.67,6.34-9.84,8.34-1.16,7-6,9.33c0,0,.06,1.92.2,4.52l-7-1.69-8.67,8.67H631s-4.33,6.5-4.83,6.83-3.84,1.84-3.84,1.84l-1.16,5.5H614a7.29,7.29,0,0,1-3.83,3.71c-3,1.37-9.5.95-9.5.95l-7.34,9.84h-5.66l-.67-4.5a23,23,0,0,1-8.3-3.34c-4-2.66-3.87-13-3.87-13l3.5-3.5s.84-3.66,0-6.16-7.66-1.17-8.66-3.84-.17-17,0-19.66-3.39-11.14-3.5-11.4l47.16.9c7.84-5.34,11.17-13.5,12.84-17.34S633.67,318,637,317s6.83.83,6.83.83a46,46,0,0,0,0-5c-.16-1.66-4.83-.16-5.66-9.83s7.16-16.5,7.16-21.33S639,268,639,268s10.67,3.83,12.33,5.33,4.17-.5,6.34-.16,3.16,1.83,4.66,2.33,10.34-7.5,10.84-7.5S693,272.83,693,272.83,709.54,282.78,714.21,284.56Z" data-name="Уйчи тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[4][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/norin-tumani">
                                        <path d="M734.67,397.33c-1.67,3.17-1.17,8-1.17,8l-11,.34-.33,8.66,3.33,5.84-5,2.5-3.33,6.66-9.34-1.16v-5.34s-8-.33-10.33-.33-3,2-4.33,2.67-5.34-.5-5.34-.5c-.66-4.34-3.16-3.84-6.66-4.67a7.73,7.73,0,0,0-6,1L671,418l-12.5.17-4.33-8-5.34.16s-6.66-3.66-8.16-4.5-3,.17-5.5.17-3.17-1-6.67-2.5-7.83,1.83-7.83,1.83l-11.84.34-6.33,5.16H598l-4.5,2-2.83,4.84s-4.5-3-5-3c-.28,0-5.49,1.31-10,2.46.31-.08,1.34-7.3,3.67-8.8s8.34-2.16,8.34-2.16h5.66l7.34-9.84s6.5.42,9.5-.95a7.29,7.29,0,0,0,3.83-3.71h7.17l1.16-5.5s3.34-1.5,3.84-1.84S631,377.5,631,377.5h4.83l8.67-8.67,7,1.69c.23,4.81.71,12,1.47,13.81,1.17,2.84,8,2.17,8,2.17s4.17-9,5.5-11.33-1-9,5.5-11.67c3.67-1.83,7.33,8.83,12,7.67,2.17-.34,2.33-10.5,4.17-11.34s5.66,4.34,5.66,4.34,4-1.67,6.5-1.17,2.17,8.83,2.17,8.83L699,382l2.17,7.67,3.5-.34C709.33,381,711,366.5,711,366.5l5.33-1.33,2.84,3.16,7.83-2,9,29.05A10.13,10.13,0,0,0,734.67,397.33Z" data-name="Норин тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[8][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/uchqorgon-tumani">
                                        <path d="M769.67,342.5c-2,2.67-7.5,2.5-10.34,3.33s-4.33,2.17-4.83,4.5,9,8.17,9,8.17-2.67,5.17-6.83,7.83-11.17,3.17-11.17,3.17l-.83,3.33s2.66,2.5,2,5.67-4,3.5-6.34,5.5,0,7.83,0,7.83a19.43,19.43,0,0,0-4.28,3.55l-9-29.05-7.83,2-2.84-3.16L711,366.5s-1.67,14.5-6.33,22.83l-3.5.34L699,382l3.5-10.17s.33-8.33-2.17-8.83-6.5,1.17-6.5,1.17-3.83-5.17-5.66-4.34-2,11-4.17,11.34c-4.67,1.16-8.33-9.5-12-7.67-6.5,2.67-4.17,9.33-5.5,11.67S661,386.5,661,386.5s-6.83.67-8-2.17c-.76-1.84-1.24-9-1.47-13.81-.14-2.6-.2-4.52-.2-4.52,4.84-2.33,3.84-7.33,6-9.33s8.84-5.84,9.84-8.34-.84-5.66,2.5-8.5,12-8.33,14.5-10.33,15.33-16.5,12-23.33c0,0,7.66-6.84,9.66-10.84s8.38-10.77,8.38-10.77a3.78,3.78,0,0,0,1,.27c2.5.17,11.5-8.16,14.16-10s8.34-3,10-2.66,7.5,3.16,9.5,3.66,2.5-2.5,6.17-2.66,9.83,8.66,10.83,11,.67,11.83,1.84,15.5a31.66,31.66,0,0,1,1,10.66c-.17,2.5,2,8.5,2.33,12.5s3.83,6.5,4.67,9.34S771.67,339.83,769.67,342.5Z" data-name="Учқўрғон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[6]?.districts[6][`name_${lang}`]}>
                                    <Link to="/namangan-viloyati/toraqorgon-tumani">
                                        <path d="M508.67,376.49s-2.84.18-6.5,0a6.84,6.84,0,0,0-5.67,2.83v5.34s-5,5.16-7,11,4.17,11.66,5.67,17.16-1.34,12.34-1.34,12.34l2.67,6s-5.17,5-10.83,3.33a29.8,29.8,0,0,0-9.84-1.33L469.67,427s-7-.33-11-.5-5.17-2.5-10.5-3.17-8.34,3.84-12,4.17-6.84-3.67-9-4.5a64.57,64.57,0,0,0-9.17-2.25c-3.75-.75-9.17,3.42-9.17,3.42l-4.33-3.84,7.17-2,7.66-6V400.5c0-.67,4.17-6.67,4.17-6.67l8.83-4.66s7.34,4.16,11.84-1.5-2.17-16-2.17-16,.11-7.51,0-15.33l3.73-1.84s1.77,7.67,4.6,7.17,1.67-2.5,1.67-3.62,3.5-9.05,4.5-13.38.67-16.34.67-19.17,4.83-7,5.33-9.64S461.67,291,464,290s7.67,7,9.33,10.5,7.5,5.5,7.5,5.5l-2,5.67,1.34,10.83-2.34,5.17L480,331h3.5l2.67,5.17h2.93S494.17,342,498,347s2.67,8.67,2.67,8.67a18.27,18.27,0,0,1,3.56,2.86C511.37,365.94,508.67,376.49,508.67,376.49Z" data-name="Тўрақўрғон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default NamanganMap;
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 919 659">
    <g id="Слой_3" data-name="Слой 3">
        <g id="Наманганская_область" data-name="Наманганская область">
            <path data-id="7" class="cls-5"  style="fill: rgb(217, 246, 247);"></path>
            <path data-id="2" class="cls-5" ></path>
            <path data-id="4" class="cls-5" ></path>
            <path data-id="3" class="cls-5" ></path>
            <path data-id="5" class="cls-5" ></path>
            <path data-id="11" class="cls-5" ></path>
            <path data-id="1" class="cls-5" ></path>
            <path data-id="10" class="cls-5" ></path>
            <path data-id="9" class="cls-5" ></path>
            <path data-id="6" class="cls-5" ></path>
            <path data-id="" class="cls-6" d="M559.51,339.19h0"></path>
            <path data-id="" class="cls-6" d="M566.17,340.77h0"></path>
            <path data-id="8" class="cls-5" ></path>
        </g>
    </g>
</svg>