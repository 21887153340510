import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function AndijonMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[13][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/ulugnor-tumani">
                                        <path d="M252.25,344.25l-4.75,2.25c-4.75,2.25-7.5,0-7.5,0l-3.75,2.5,5.5,7.5-4.5,5.75-5-3.75L225.51,369l4.49,6s-4.5,16-4.49,23.5,1.74,26,1.74,26l-1.74,3.8-8.68-9.8-12.66-6.33-11.84-12.5-13.5-7.5S165.5,394,164.67,394s-16.34-11.33-16.34-11.33l-7.83-11-7.67-1.5L125,379.83a119.93,119.93,0,0,1,.67,26.67l5,12-9.84,3.5L106.5,439.5c-9.17-27.5-16.67-32.67-16.67-32.67l-5.33-15.5s-6.33-8.16-6.83-8.33l-16.84-6a81.82,81.82,0,0,1,7.34-6.17C70.5,369.33,94,361,94,361s-3.17-12-6.17-18.67a31.07,31.07,0,0,0-9-11s6.5-3.5,10-5.5S101.5,317,101.5,317l-5-3.83,7.67-5.84,8.5-.66s8.16-8.5,9.83-9.84,28.5-11,31.67-14.33,4.66-10.67,4.66-10.67l7-.66,4.85,3.73,4-1.23,4.83-8.17,7.29-1.82L190,271s6.75.75,7.75,5.25,1.25,9.75,4,12.25,14,8.75,14.75,11.25S214,318,215,321s5,10,8.75,8.5,8.75-4.25,10.25-2.25-4,4.25,0,5.75,9,4.25,10.75,4.25,6.75-2,7.5,0S252.25,344.25,252.25,344.25Z" data-name="Улуғнор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[3][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/baliqchi-tumani">
                                        <path d="M432,305.25,433.5,308c-10.25,1-9.75,3.25-12.75,5.32s-6.25-.07-10.5,2.18-10,.75-16.5.75-6.75.25-10,2.5-9.25,2.5-14,.75-4.75-5.75-5.25-9.56-5.25-4.19-7.75-3.69-4,1.25-5.5-2.29-5.75-3.46-9.25-4.71-7.25,1-10.75,2-10.25,0-10.25,0L313.5,308c-6.57-8.66-13.25-2-17.25,2s0,11,0,11C288,322,285,331.25,280,336s-12,3.75-12,3.75l-15.75,4.5s.75-5,0-7-5.75,0-7.5,0S238,334.5,234,333s1.5-3.75,0-5.75-6.5.75-10.25,2.25S216,324,215,321s2.25-18.75,1.5-21.25-12-8.75-14.75-11.25-3-7.75-4-12.25S190,271,190,271l-3.21-7.32,13.38-3.35s1.83,3.84,4.83,3.34,0-5,7.17-7.5c2.66-1.67,10.16-1.34,10.16-1.34l6.67-6h13.67l9.83-1.66s4.67,1.66,6.67,2.33,7.66-.83,7.66-.83,5.5,3.5,8.34,5.16,9,.84,9,.84l6,10,14.66-.67,5.67,4.17,3.67-1.84,7.5.5,4.33,6,4.83,1.17,6.17-4.67,12.17.84,1.16,7,12,1.33,3.5-8.5,6-2.67,5.42,11.92v7s6.5,2.5,8.25,5.5,2.5,7,3.25,7,13.5,5.25,19.25,3.5a23.2,23.2,0,0,0,9-5l11.75-.25,4.75,4Z" data-name="Балиқчи тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[16][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/shahrixon-tumani">
                                        <path d="M396.5,429.75c-1,2-18.5,2-18.5,2A41.12,41.12,0,0,1,366.5,439c-6.75,2.75-16,6.25-16,6.25l-2.83,16.25L337.33,459a52.31,52.31,0,0,1-12.83,2.33c-7.67.5-8.17-2.66-11.33-3.33l3.33-31.25c-2.25-12-9.25-18-9.25-18a15,15,0,0,1-6.5-1.25c-3.5-1.5-1.25-8.75-1.25-8.75s6.5-4,8.75-7.25,3-15.25,3-15.25l-10.75-1.5L283.5,349l-9.5-2.25-6-7s7,1,12-3.75,8-14,16.25-15a17.11,17.11,0,0,1,13.25,3.75l4.5-1.5s8.5-11.5,13.75-9.75,5.75,16,5.75,16,5.25,2.5,6.5,6,4.25,18.5,4.25,18.5,6.75,10,11,8.75,6-8.75,9.25-8.5,6.75,6.25,9.75,5.25c6.5-1,5.5-8.5,8-8s3.55,7.29,14.55,10.29A46,46,0,0,0,391,372.5c-2.25,6.5,2.5,26.38,2.5,26.38L388.75,406h-9l-.25,7a24.77,24.77,0,0,1,5.5,1.25,11.93,11.93,0,0,1,4.75,3.75s4.5-1.5,8.25,0S397.5,427.75,396.5,429.75Z" data-name="Шаҳрихон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[5][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/boz-tumani">
                                        <path d="M316.5,426.75,313.17,458c-3.17-.67-26-6.33-26-6.33s-5.34,4.16-10.5,5-22-4.67-22-4.67l-11-11.5-11.5-4.67-6.66-7.53,1.74-3.8s-1.74-18.5-1.74-26S230,375,230,375l-4.49-6,6.74-10.52,5,3.75,4.5-5.75-5.5-7.5,3.75-2.5s2.75,2.25,7.5,0l4.75-2.25,15.75-4.5,6,7,9.5,2.25,17,25.75,10.75,1.5s-.75,12-3,15.25-8.75,7.25-8.75,7.25-2.25,7.25,1.25,8.75a15,15,0,0,0,6.5,1.25S314.25,414.75,316.5,426.75Z" data-name="Бўз тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[7][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/izboskan-tumani">
                                        <path d="M529,237.75c.75,5,.75,9-2.5,11.5s-11,2.25-12.5,8a18.85,18.85,0,0,0,0,10.08c-3,2.59-6.25,1.42-10.25,1.17s-4.75,1.75-9.25,5.75-6.75,2.5-11.5,4.75-2.5,4.25-5.25,7.75-6.25,2.25-9.5,3.75-4.5,8.25-4.5,8.25L455.5,304l-9,1.54L442,308h-8.5L432,305.25,433.5,301l-4.75-4-11.75.25a23.2,23.2,0,0,1-9,5c-5.75,1.75-18.5-3.5-19.25-3.5s-1.5-4-3.25-7-8.25-5.5-8.25-5.5v-7l-5.42-11.92-2.76-6.5V249.69l13.1-.86V243l3-7.83L389.5,233v-5.33l2.33-6.34,4.67-2.83a29.46,29.46,0,0,0,2.33-4.17c.67-1.66-3.33-5.33-3.33-5.33v-4.17l9.83-2,9.67-4.66,4.5-6.84c9.17,1,13.33-2.66,20.17-6.66s22.16-8.34,25-9.5a8.22,8.22,0,0,0,1.13-.59L463,183s2.05,4.25,1.4,5-3.16,17.25-3.16,17.25l19,10.25,5,6.5s-3,10.25,0,12c7.25,3,15.5-5.75,15.5-5.75s15.75,1,19.5-1l4.25-4.5,5.75,4.5S528.25,232.75,529,237.75Z" data-name="Избоскан тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[2][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/asaka-tumani">
                                        <path d="M500.68,439c-3.41.1-6.26.37-7.93.55-4.75.5-15.25,9-15.25,9l-21.75-1.75s-10,12-14.5,13.5S430,456.5,430,456.5l-5.25,1.5v6.5l-2.5,1.25-5.5-3.25h-5.42l-3.83,9,10.25,11.25,1.5,5.5-7.92,9.25a18.82,18.82,0,0,0-15.66.67l-8-8s-4.34,1.66-7.5,1.5-9.84-6.5-9.84-6.5l-5.16-11.5-5.84,4.83h-8L348.5,470l-.83-8.5,2.83-16.25s9.25-3.5,16-6.25a41.12,41.12,0,0,0,11.5-7.25s17.5,0,18.5-2S401.75,419.5,398,418s-8.25,0-8.25,0a11.93,11.93,0,0,0-4.75-3.75,24.77,24.77,0,0,0-5.5-1.25l.25-7h9l4.75-7.12S388.75,379,391,372.5a46,46,0,0,1,5.8-10.71c2.4-3.36,4.95-6,6.7-6.54,3.5-1,14.5,1.75,19.25-2a7.27,7.27,0,0,0,5.5,5.75c4.75,1,11.75-3,19,.5s15,12.25,15,12.25A27.12,27.12,0,0,1,467.83,388c.17,10-6.59,14-6.59,14l1,4H467s3.33,5.17,3.33,5.83-1.16,10.17.17,10.67,13.6-8.5,13.6-8.5-.8-6.29,2.26-9.17l.06-.05c1.63,1.8,3.46,4.31,3.08,6.22-.67,3.33-.84,12-.75,16.25,0,0-6.2,5.75-2.39,8.42s7.64,0,7.64,0l5.67-.34Z" data-name="Асака тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[4][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/buloqboshi-tumani">
                                        <path d="M608.75,466.25c.75,4,3.75,5.75,3.5,8.5a5.17,5.17,0,0,1-5.42,5c-3.33,0-3.58,2.5-3.33,5.25s1.25,4.25-3,4-6.5-10-12.5-8.5a106.19,106.19,0,0,0-11.5,3.66,10.22,10.22,0,0,0-3.33-1.16c-2.84-1.17-10.34,2.67-10.34,2.67s-3-3.67-9.66-11.34-11.34-18.66-11.34-18.66S527.67,442,521.17,442c-6.08-2.79-14.22-3.23-20.49-3.05l-1-3.62-5.67.34s-3.83,2.66-7.64,0,2.39-8.42,2.39-8.42c-.09-4.25.08-12.92.75-16.25.38-1.91-1.45-4.42-3.08-6.22a4.89,4.89,0,0,1,1.91-1.11c5.34-1.67,13.67,1,13.67,1s5.33-7.34,8.17-6.67a25.38,25.38,0,0,1,6.08,2.63,23.32,23.32,0,0,0,0,4.18c1.5,17.69,19.5,10.19,19.5,10.19l5.25-3.5,6.33,1.25-1.58,3.83-5.75,5.3,20.42,10.62s11.08-3.5,16.08,0,6,6.75,11.75,9S605,446.75,605,446.75l1.83,3.5s-5.33,2-3.08,3.75c6.5,3,7.75-4,10.75-2.25s2,5,0,7S608,462.25,608.75,466.25Z" data-name="Булоқбоши тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[10][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/marhamat-tumani">
                                        <path d="M527.33,568.33s4.84,12.5,2.17,17.17-6.5.67-12.67,0-12.66-5.33-15.16-7.83S481,557.5,478.5,557s-10.33,3.17-10.33,3.17l-4.84-3.34s-5.33-8.5-7-9-8.16,0-11.16,0-3.84-4.83-3.84-4.83,3.84-11.33,3.84-13.83S439.5,524,439.5,524c-12.83,2.17-13.83.83-18.33,0a34.51,34.51,0,0,0-9.84,0V497.5l7.92-9.25-1.5-5.5L407.5,471.5l3.83-9h5.42l5.5,3.25,2.5-1.25V458l5.25-1.5s6.75,5.25,11.25,3.75,14.5-13.5,14.5-13.5l21.75,1.75s10.5-8.5,15.25-9,19-1.81,28.42,2.5c-3.34-.17-9.84,4.17-10.5,4.17H499.83s-4.33,3-3.83,6.83,10.83,3.5,13.83,6.83a39.78,39.78,0,0,1,7,12.34c1.34,3.83,16.5,4.83,17.34,12.16s-6.34,10-6.34,10h-9L515,496.67,513.33,502l1.5,5,6.5-.5a6.51,6.51,0,0,1,5-1l3.5,5a88.71,88.71,0,0,0,.67,12.5c.83,5.83,6.67,8.83,6.67,8.83l2.5,8.5Z" data-name="Марҳамат тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[12][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/paxtaobod-tumani">
                                        <path d="M606.83,265.33c-2.66,3.84-2.83,5.92-11.83,5.92s-13.25-6.5-17.25-9.08S564,259,547.25,257.25s-17.75,2-24,6.75-9.25,3.33-9.25,3.33a18.85,18.85,0,0,1,0-10.08c1.5-5.75,9.25-5.5,12.5-8s3.25-6.5,2.5-11.5,1.25-10.5,1.25-10.5l-5.75-4.5-4.25,4.5c-3.75,2-19.5,1-19.5,1S492.5,237,485.25,234c-3-1.75,0-12,0-12l-5-6.5-19-10.25s2.51-16.5,3.16-17.25-1.4-5-1.4-5l2.8-8.42c2.65-1.59,5.88-4.94,9.37-7.41,4-2.84,7-6.34,11-11.34s8.33-5.66,10.66-5.5,5.17,1.67,9.84,3.5,10.33,4.34,12.83,6.5,3.33,2.84,7.83,2.84,5.17,0,9.17,2.66,6.83,2.34,12.5,1.67,15,2.17,18.33,5,3.17,10.83,2.17,17.33-6.17,13-8,17-.5,6.84,1.83,7.34,4.5-8,4.5-8l6-1.67s4.34,1.5,6.67,4,10.5,13.83,13.33,19.33S602.5,241,605,244,605.83,262.17,606.83,265.33Z" data-name="Пахтаобод тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[9][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/qorgontepa-tumani">
                                        <path d="M875.17,327.5c-3.5,4.5-21.67,10.83-21.67,10.83L839.67,344l-18.5,1.83s-4.34,7.17-7.5,10.67S783.5,374,783.5,374l-5.33.33-.34,3.5L768,379.67s-13.83,8.16-14.83,9.16c-7.17,9.67-15.5,11-21,12.67s-16,.5-16,.5l-10.34.83S699,402.25,696,404s-3.5,5.25-5.75,5.75-4-2.75-6-1.25-10.25,6.75-13.25,9-15.5,9.75-15.5,9.75-5.75-11.5-8-15.75-9.67-10-9.67-10,1.42-9,8.92-12.25,16-7,19.25-9.5,5.83-4.75,5.83-4.75h8.67v-2.5s-3.5-3-2.75-6.75,8.25-7.75,9-10.5,2-7.25,2-7.25-6-7.25-2-18.5,20.5-6.25,20.5-6.25l1.5-4-2.25-4.75s6.5-13,1-14.75-13,5.25-22.5,5-22.5-5.28-22.5-5.28l-8.5,1-59-23.25v-6c9,0,9.17-2.08,11.83-5.92,1,3.17,20,9.34,20,9.34l32.34,13.66a27.51,27.51,0,0,1,7.16-2.5c3.34-.5,19.67,3.5,19.67,3.5s6.83-3.33,13.83-5.83,7.84,1.17,12,1.33,15.84-3,23.5-5.83,15.5,2.83,18,5.33S766,299.5,768,302s2.33,8.5,4.5,11.33,14.33,3.34,14.33,3.34l7.5-2.5,1.5-3.34s3.5-1.66,6.34-3.33,5.16-5.83,5.16-5.83l7.34-.34L818,299v-4.33L810,289l-3.5-5.33,3.5-14,4.17-4.34H818l.5,3.17L829.83,282l8.67,1.67s9.5,9,12.67,9,10.16-5.84,10.16-5.84,15.84-3.33,21,5.34S878.67,323,875.17,327.5Z" data-name="Қўрғонтепа тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[6][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/jalaquduq-tumani">
                                        <path d="M686.75,329.5c-4,11.25,2,18.5,2,18.5s-1.25,4.5-2,7.25-8.25,6.75-9,10.5,2.75,6.75,2.75,6.75V375h-8.67s-2.58,2.25-5.83,4.75-11.75,6.25-19.25,9.5-8.92,12.25-8.92,12.25,7.42,5.75,9.67,10,8,15.75,8,15.75,12.5-7.5,15.5-9.75,11.25-7.5,13.25-9,3.75,1.75,6,1.25,2.75-4,5.75-5.75,9.83-1.17,9.83-1.17l-4.16,5,1.5,7.34,4.16,4.83-.33,4.5-7.83,6.33v32.92S691.5,455,686,452.25s-8-1.25-12.25-5.5-19.25-9.5-19.25-9.5l-4.5-1.5v-3.5s-9.75-9-12.5-14.75-5-13.5-18.5-16.75-13.25.25-19-4.5c0,0,1.25-9.5-3.75-14s-12.25-8-17.75-9.75l-2-6.5,2.75-2c6.25,3,12.5,4.5,14,2.25S588.5,360,588.5,360l-1.5-3.75L593.75,344,588,342.25l1.5-9.5,5.62-4.75,10.13-3V310.75l-8.75-6L585,296s3.25-9.75,3.75-12.5,6.25-6.25,6.25-6.25l59,23.25,8.5-1s13,5,22.5,5.28,17-6.75,22.5-5-1,14.75-1,14.75l2.25,4.75-1.5,4S690.75,318.25,686.75,329.5Z" data-name="Жалақудуқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[15][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/xojaobod-tumani">
                                        <path d="M699.17,463.75v12.92l-6.5,2-5.84-3.34L676,464l-15.33-3.17-3.17,4.5-1.67,7.84L646.17,482s-2.5,15-2.5,15.83-7.84,12-7.84,12l-9.5,1L610,498.33l-16,.17s-5-3.5-7.83-5.17c-2.31-1.35-5.17-6.7-9.67-9.17A106.19,106.19,0,0,1,588,480.5c6-1.5,8.25,8.25,12.5,8.5s3.25-1.25,3-4,0-5.25,3.33-5.25a5.17,5.17,0,0,0,5.42-5c.25-2.75-2.75-4.5-3.5-8.5s3.75-5.5,5.75-7.5,3-5.25,0-7S610.25,457,603.75,454c-2.25-1.75,3.08-3.75,3.08-3.75l-1.83-3.5s-11-3-16.75-5.25-6.75-5.5-11.75-9-16.08,0-16.08,0L540,421.88l5.75-5.3,1.58-3.83L541,411.5l-5.25,3.5s-18,7.5-19.5-10.19a23.32,23.32,0,0,1,0-4.18c1.29-14.27,16-18.19,19.25-18.88,3.5-.75,5.5-5.5,5.5-5.5s2.75-1.75,8.5-2.63,16,1.13,16,1.13c4.5-1.5,13-2.25,13-2.25,5.5,1.75,12.75,5.25,17.75,9.75s3.75,14,3.75,14c5.75,4.75,5.5,1.25,19,4.5s15.75,11,18.5,16.75S650,432.25,650,432.25v3.5l4.5,1.5s15,5.25,19.25,9.5,6.75,2.75,12.25,5.5S699.17,463.75,699.17,463.75Z" data-name="Хўжаобод тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[11][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/oltinkol-tumani">
                                        <path d="M462.25,371.75s-7.75-8.75-15-12.25-14.25.5-19-.5a7.27,7.27,0,0,1-5.5-5.75c-4.75,3.75-15.75,1-19.25,2-1.75.5-4.3,3.18-6.7,6.54-11-3-12.05-9.79-14.55-10.29s-1.5,7-8,8c-3,1-6.5-5-9.75-5.25s-5,7.25-9.25,8.5-11-8.75-11-8.75-3-15-4.25-18.5-6.5-6-6.5-6-.5-14.25-5.75-16S314,323.25,314,323.25l-4.5,1.5A17.11,17.11,0,0,0,296.25,321s-4-7,0-11,10.68-10.66,17.25-2l7.5-6.75s6.75,1,10.25,0,7.25-3.25,10.75-2,7.75,1.18,9.25,4.71,3,2.79,5.5,2.29,7.25-.12,7.75,3.69.5,7.81,5.25,9.56,10.75,1.5,14-.75,3.5-2.5,10-2.5,12.25,1.5,16.5-.75,7.5-.11,10.5-2.18,2.5-4.32,12.75-5.32H442l4.5-2.5,9-1.54,8.25-5.21v12.5s6,3.5,6,4.5-3.75,13.5,0,16.5,12-2.5,14,4.5S479.25,361,462.25,371.75Z" data-name="Олтинкўл тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[1]?.districts[1][`name_${lang}`]}>
                                    <Link to="/andijon-viloyati/andijon-tumani">
                                        <path d="M605.25,310.75V325l-10.13,3-5.62,4.75-1.5,9.5,5.75,1.75L587,356.25l1.5,3.75s6.25,4,4.75,6.25-7.75.75-14-2.25l-2.75,2,2,6.5s-8.5.75-13,2.25c0,0-10.25-2-16-1.13s-8.5,2.63-8.5,2.63-2,4.75-5.5,5.5c-3.2.69-18,4.61-19.25,18.88a25.38,25.38,0,0,0-6.08-2.63c-2.84-.67-8.17,6.67-8.17,6.67s-8.33-2.67-13.67-1a5,5,0,0,0-2,1.16c-3.06,2.88-2.26,9.17-2.26,9.17s-12.27,9-13.6,8.5-.17-10-.17-10.67S467,406,467,406h-4.75l-1-4s6.76-4,6.59-14a27.12,27.12,0,0,0-5.58-16.25c17-10.75,23.5-28,21.5-35s-10.25-1.5-14-4.5,0-15.5,0-16.5-6-4.5-6-4.5v-12.5s1.25-6.75,4.5-8.25,6.75-.25,9.5-3.75.5-5.5,5.25-7.75,7-.75,11.5-4.75,5.25-6,9.25-5.75,7.25,1.42,10.25-1.17c0,0,3,1.42,9.25-3.33s7.25-8.5,24-6.75,26.5,2.34,30.5,4.92,8.25,9.08,17.25,9.08v6s-5.75,3.5-6.25,6.25S585,296,585,296l11.5,8.75Z" data-name="Андижон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default AndijonMap;