import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function BuxoroMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[10][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/peshku-tumani">
                                        <path d="M509.5,369.5V378l-3.67,5-10.5,1.17-29.83-13s-16.33-7.67-18.5-11.84-3.67-11.83-6-15.5-4.67-12.83-9.83-12-3.67,6.17-3.5,11.5c.06,2.09.15,4.91.23,8l-14.23-7.65-10.34-10-11-20.84-9-1-5.5,1.67-7.16-5.83-12.84,2s-9.33.16-10.16,0-5.5-4.45-5.5-4.45l-12-2.05-19.5,6.5s-16.17-17.67-19-21-17.17-12.17-19.5-13-10,2.5-10,2.5l-10.84-4-39.15-23.5L212,239s11-3.75,14.25-7,4.25-8.75,4.25-12.25,2.5-13,2.5-13L171,124.5l-8.25-2s-13.5-18.75-16.5-23.25S115,46,115,46s35.25-6.25,41.25-6.75,35.25-10,35.25-10,7,27.5,8,27.5,32.75,35.5,36.5,40.5S254.5,132,254.5,132l21.17.17s61.83,29.66,65.16,32,42.34,34,42.34,34l3.5,11.16,3.87,7.17,7.71,5.75v24.5h4.25l6.25,15H414l3.5,3.25h5.25l6.25,8.5s-.75,6.75.25,7,16.5,17.5,16.5,17.5H461.5L482,328.74l13.5,32.51s4.75.75,5.5,1.25,5.75,3.25,5.75,3.25Z" data-name="Қоровулбозор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[7][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/qorakol-tumani">
                                        <path d="M426.17,476.17l.09,14.42-7.93-3.26-19,10.5v7.34L388.54,508l-6.87,9.5-.67,8.17,2,8.5-.67,8.33-8.78,6c-5.08-5.88-11.71-12.88-15.38-16.68-5.17-5.33-17.34-14.83-23.34-19s-25.16-15.5-31.16-20.16-140-101.34-143.17-103.34-5.17-7.16-5-9.83.33-3-.67-4.83-.5-4-.5-7-4-4.34-5.5-8.34,2-4,2.84-6.33-.5-4.83-.17-10.33-2-5-1.5-7a8.79,8.79,0,0,0-1.83-6.93c-1.67-2.26.16-5.91-1.17-8.91s.5-5.66,1.33-10.5-6.33-8.83-9.16-12.5S138,289,140.17,284a8.07,8.07,0,0,0,.66-2.24l26.67,7.74,16,8.5,20.56-18.43h0c.76,0,55.11,27.61,58.1,30.93s13.33,14.33,13.33,14.33-10.33,9.67-5.67,14.17,15.84,5.33,19.5,6.5,13.5,5.33,16.34,7.83,9.16,12,9.16,12,9,2.5,10.67,9.34,1,9.33,1,9.33-12.5,12-13,17.83,2.33,13.67,2.33,13.67l7,.33s5.67-2.16,7.17,2.67,7.33,15,7.33,15,8.5-.67,10.34,1.67a39.28,39.28,0,0,0,8.16,7.5c2.67,1.66,6.34,7.83,9,10.33s4.5,2.5,10.17,8.5,4.5,9.5,10.83,11.67a109.38,109.38,0,0,1,11.17,4.5h6.83s5.34-12.67,6.17-12.5,4.83,3,4.83,3l-6.5,11.33,4.17,1.17,4.17-2.17L420,469l8.67,2.5Z" data-name="Когон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[9][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/olot-tumani">
                                        <path d="M544.06,589.87l-20.73,5.8s-7.5,7.33-11,10.16-16.16,11-20.16,12.34A63.51,63.51,0,0,0,480,624c-2.83,1.83-11,1.17-13.33,1.17s-13.17-9.17-19.84-15-35.83-27.67-46-34.5a90.57,90.57,0,0,1-22.16-20.84c-1.25-1.73-3.06-3.93-5.12-6.32l8.78-6,.67-8.33-2-8.5.67-8.17,6.87-9.5,10.79-2.83v-7.34l19-10.5,7.93,3.26h0L473,509.83l8.79,4.75c7,3.79,15.74,8.49,16,8.59.5.16,13.5,12.83,14.17,13s11.33,7.33,11.33,7.33L522,553.17,517.17,564l12.66,11.33Z" data-name="Олот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[12][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/shofirkon-tumani">
                                        <path d="M549.17,336.17l-3,5-3.84,2-6.16,12.33L515.5,365.75l-4.17-2.58-4.58,2.58s-5-2.75-5.75-3.25-5.5-1.25-5.5-1.25L482,328.74,461.5,298H445.75s-15.5-17.25-16.5-17.5-.25-7-.25-7l-6.25-8.5H417.5l-3.5-3.25h-5.25l-6.25-15h-4.25v-24.5l-7.71-5.75-3.87-7.17,21.16-4.16L432.67,154l10.66-2,8.5,7.33c.84,9.67-.66,29.34-.66,29.84S485,197.33,485,197.33l22.83-.33c1.17,6.5,3.5,4.5,6.5,8s3,14,3.34,20.83,9,24.67,9.66,31-2.16,25.5-2.16,25.5l12.66-1.66,4,2.66-2.16,4.17v26.67L537,328.74l3.67,4.93,6.16-1.67Z" data-name="Ромитон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[3][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/gijduvon-tumani">
                                        <path d="M676,229.33c-.83,13.17,3,27.5,3,27.5l-22.67-1.33,1,9.83c-3.33,3.84-5.33,16.5-5.33,16.5l-8,2-4,16.84s-24.17-1.17-25-1.17-6.5-5-6.5-5-13.67-2.83-14.17-2.67-7.16,9-7.16,9v6.84l-3.34,2.16-3.83-3-6,5.34s-6.5-.34-7-.5-2.17-3.67-2.17-3.67l-8.33,4.67-3.67.33-2.16-4h-3.34l-3,5.17h-4.66V287.5l2.16-4.17-4-2.66-12.66,1.66s2.83-19.16,2.16-25.5-9.33-24.16-9.66-31-.34-17.33-3.34-20.83-5.33-1.5-6.5-8l4.5-11.5-.66-16.17,29.33.17,24.33-5-.5-15.67s7.34,0,7.34.5,7,48.67,7,48.67l17,11.5s15.16,3.17,19.83,4,35.33.67,35.33.67L668,219.83l11.83-4.33-.83,6.67Z" data-name="Вобкент тумани" fill="transparent" stroke="#fff" strokeWidth="2">
                                        </path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[2][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/vobkent-tumani">
                                        <path d="M595.5,332l-.5,5.5-4,6.17-13.67,3.66-4,18.84L548,381.33l-4.83,8L545,404l-5.17-4.33-10.33.83-19.33-.83-7.55,1.43,3.21-18.1,3.67-5v-8.5l-2.75-3.75,4.58-2.58,4.17,2.58,20.67-10.25,6.16-12.33,3.84-2,3-5L546.83,332l-6.16,1.67L537,328.74l2.67-14.57h4.66l3-5.17h3.34l2.16,4,3.67-.33,8.33-4.67s1.67,3.5,2.17,3.67,7,.5,7,.5l6-5.34,3.83,3,3.34-2.16,6.5,7-3.5,11.83Z" data-name="Шофиркон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[5][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/kogon-tumani">
                                        <path d="M564.33,431.33,563.17,438l-2.34,4H555.5l-12.83,18-9.34,5-8,2L520,478l-5.17,1.67h-7.66v-21.5l7.66-2,2.67-3.5,1-10.34,5.83-10.16s2.16-25.34,2.67-25.34c.5.17,7.33,0,7.33,0l3.84-2.33,3.16,1.33L545,404s5.83,5,6,5.83,2.83,12,2.83,12l6.67,1.34Z" data-name="Жондор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[8][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/qorovulbozor-tumani">
                                        <path d="M638.83,497.17l-1.16,11.5s-3.17,5.83-3.5,6.33-16.67,13.17-16.67,13.17-8,2.66-9.33,2.83-20.34,21.67-20.34,21.67l10,22.16-53.77,15-14.23-14.54L517.17,564,522,553.17l1.33-9.67s-10.66-7.17-11.33-7.33L517.67,524l3.83-3.83v-6l-2.17-3.34,2.17-10.33,5.83-10L520,478l5.33-11,8-2,9.34-5,12.83-18h5.33l2.34-4,1.16-6.67,11,3.5-.33,4,8.83,4.84,2.84-4.67,4.83.17L594,444c3.67,3,22.5,7.33,22.5,7.33l.17,24.67L634,479Z" data-name="Қоракўл тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[4][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/jondor-tumani">
                                        <path d="M479,459.17l-2.81,7,.83,17.58,4.79,9.58s-.08,21.21,0,21.25L473,509.83,426.26,490.6h0l-.09-14.42,2.5-4.67L420,469l-3.33,9.5-4.17,2.17-4.17-1.17,6.5-11.33s-4-2.84-4.83-3-6.17,12.5-6.17,12.5H397a109.38,109.38,0,0,0-11.17-4.5C379.5,471,380.67,467.5,375,461.5s-7.5-6-10.17-8.5-6.33-8.67-9-10.33a39.28,39.28,0,0,1-8.16-7.5c-1.84-2.34-10.34-1.67-10.34-1.67s-5.83-10.17-7.33-15-7.17-2.67-7.17-2.67l-7-.33s-2.83-7.83-2.33-13.67,13-17.83,13-17.83.67-2.5-1-9.33-10.67-9.34-10.67-9.34-6.33-9.5-9.16-12-12.67-6.66-16.34-7.83-14.83-2-19.5-6.5,5.67-14.17,5.67-14.17-10.33-11-13.33-14.33-57.34-30.93-58.1-30.93L212.5,272l-.32-21.33,39.15,23.5,10.84,4s7.66-3.34,10-2.5,16.66,9.66,19.5,13,19,21,19,21l-1.34,5-3.66.16-6.84,4.67-.66,7.77,2.16-.94,6.5.94,3.17-2.6,10,4.83,12.46,14v7.82l3.5,4.18,4.67-2.5v-8.17l2.66-1.33,2.84-3.33L357,341.5l4.33-4.5h4.84l2-3-.84-4.5s6.17-4.83,8.67-7.83,1.83-8.17,1.83-8.17l5.5-1.67,9,1,11,20.84,10.34,10,14.23,7.65c.12,4.79.2,10.21.1,14.43-.17,6.92-1.33,14.08-3.67,17.58s-7.16,9.17-6.5,9.34,18.34,21.66,24,25.33,9.84,7,11.67,11.17l13.67-16.84,13.5,8.5h4.5L479,434.33l-1.48,6.5,4.29,3.67L479,449.83Z" data-name="Ғиждувон тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[1][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/buxoro-tumani">
                                        <path d="M545,404l-3.67,1.83-3.16-1.33-3.84,2.33s-6.83.17-7.33,0c-.51,0-2.67,25.34-2.67,25.34l-5.83,10.16-1,10.34-2.67,3.5-7.66,2v21.5h7.66L520,478l7.33,12.5-5.83,10-2.17,10.33,2.17,3.34v6L517.67,524,512,536.17c-.67-.17-13.67-12.84-14.17-13-.3-.1-9-4.8-16-8.59-.08,0,0-21.25,0-21.25L477,483.75l-.83-17.58,2.81-7v-9.34l2.81-5.33-4.29-3.67,1.48-6.5,6.19-13.5,6-8,2.33-10,9.12-1.73,7.55-1.43,19.33.83,10.33-.83Z" data-name="Бухоро тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[11][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/romitan-tumani">
                                        <path d="M233,206.75s-2.5,9.5-2.5,13-1,9-4.25,12.25S212,239,212,239l.18,11.67L212.5,272l-8.43,7.57h0L183.5,298l-16-8.5-26.67-7.74c.34-2.94-2.36-3.35-4.83-3.76-3-.5-5.17-1.83-7.17-5.33S123,267.5,119.67,266s-6-3.33-6.67-6.17-4.83-4.33-7.83-6-3.92-6.33-3.92-6.33l57-45,7-19.75-37-42.5,34.5-17.75,8.25,2Z" data-name="Пешку тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[11][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/romitan-tumani">
                                        <path d="M376,321.67c-2.5,3-8.67,7.83-8.67,7.83l.84,4.5-2,3h-4.84L357,341.5l-10.83-1.33-2.84,3.33-2.66,1.33V353L336,355.5l-3.5-4.18V343.5L320,329.5l-10-4.83-3.17,2.6-6.5-.94-2.16.94.66-7.77,6.84-4.67,3.66-.16,1.34-5,19.5-6.5,12,2.05s4.66,4.28,5.5,4.45,10.16,0,10.16,0l12.84-2,7.16,5.83S378.5,318.67,376,321.67Z" data-name="Пешку тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[2]?.districts[11][`name_${lang}`]}>
                                    <Link to="/buxoro-viloyati/romitan-tumani">
                                        <path d="M505.83,383l-3.21,18.1-9.12,1.73-2.33,10-6,8h-4.5l-13.5-8.5L453.5,429.17c-1.83-4.17-6-7.5-11.67-11.17s-23.33-25.17-24-25.33,4.17-5.84,6.5-9.34,3.5-10.66,3.67-17.58c.1-4.22,0-9.64-.1-14.43-.08-3.08-.17-5.9-.23-8-.17-5.33-1.67-10.66,3.5-11.5s7.5,8.34,9.83,12,3.83,11.34,6,15.5,18.5,11.84,18.5,11.84l29.83,13Z" data-name="Пешку тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default BuxoroMap;