import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function SamarqandMap() {
	const { lang, isLoading, zones, isToken } = useHospital()
	return (
		<>
			{
				!isLoading && zones.length > 0 && isToken
					?
					<div className="region" id="region">
						<svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
							<g className="grouped">
								<Popover title={zones[7]?.districts[11][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/paxtachi-tumani">
										<path id="paxtachi" d="M246.5,349c-2.5,4.75-6,5.75-6,8.5s5.5,6,5.5,10.25l-15-6.5c-4.75,2,.25,17.75,0,20.25s-3.5,1-5.5,2.5-2.5,8.75-2.5,8.75l-8-4.5c-5.5,5.25-3.75,18.75-3.75,18.75l5,2.25V414l-3,3.25-3.25,9.5L159.25,433s-19.5-11-28.5-14.5-26-13.25-26-13.25c-13,2.5-34.13-1.25-34.13-1.25L50.25,365.88,73,363.75l3.88,3.63h5.37l6.63-5.13v-5.37l-2.38-5.63,7.5-.87,1.88-2.63,6.24.5,7.13,4.25,6.37-3.88,3.63,5.26L132,356.5l6,4.25,4.5-1.25s8.88-13.12,8.38-13-.5-7.25-.5-7.25l-6.13-.37-2.37-12.1,7.5,2.72a107.21,107.21,0,0,0,4.74-10.12,91.26,91.26,0,0,0,1.88-9.63l-15.38.13,3.88-14.63,13.88-21.75v-8.25l3.87-2.37h4.87l7.13,9,5.5,3,5.63-.26s5.5,6.88,7.24,8.5,10.63,7.63,10.63,7.63l4.63,10.63L217,308l15.5,6.75s13-1,21.5.5c0,0-7.25,11.49-7.5,15.62S249,344.25,246.5,349Z" data-original-title="Paxtachi" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[7][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/nurobod-tumani">
										<path id="nurobod" d="M553.7,512.8c-3.2,1.8-4.5,3.3-4.2,7.5s-1.5,3.2-3.7,4.2s-2,2.8-2.7,5.5s-2.5,1.2-5.7,2.2
                			c-1.8,0.6-3.2,2.2-3.4,4.1l-3.7,4.8l-7.9-2.1l-8.3,3.7l-10.6,0.1l-5.7,5.6l-1.5,9.5l-7.5,3.4l-5.4,10.9l-7.6-1.2l-4.9-4l-0.3-5.9
                			l-3.6,0.3c0,0-0.5,11-2.5,13.6c0,0-5.7,2.6-14.2-3s-24.1-15.6-24.1-15.6s-10,0-12,1.1l-11,6.1l-3.5,4v-10l-13.1,0.3v-5.4l-6.1-9.8
                			c0,0-3.4,4.8-5.4,6.3s-11,8.5-11,8.5l-7.5-0.4l-15.7,5v11l-10.1,18.5c0,0-13.3,0-17.3-1.4s-15.3-5.5-15.3-5.5l-8.9-10.1l-1.1-12.8
                			l-12.1-3.6l1.8-19.7c0,0-10.5-7.3-14.9-13.8s-20.5-29.5-20.5-29.5l-3.4-13.9l-18,0.4l5.6,14.8c0,0-10.8,13.7-20.8,19.7l-17.1,1.3
                			l-6.3-6.9c0,0-10.1,4.4-13.2,7.5c0,0-24.4-2.9-28.9-2.5s-17.3,1.5-25.6-0.5s-36.3-6.6-36.3-6.6l-10.7-17.9
                			c-0.7-9.3-0.5-18.7,0.6-28c1.9-13.5,4.8-24.8,4.8-24.8S74,424,73,418.4S70.6,404,70.6,404s21.1,3.8,34.1,1.3c0,0,17,9.8,26,13.3
                			s28.5,14.5,28.5,14.5l50.8-6.3l3.3-9.5l3-3.3v-4.8l-5-2.3c0,0-1.8-13.5,3.8-18.8l8,4.5c0,0,0.5-7.3,2.5-8.8s5.3,0,5.5-2.5
                			s-4.8-18.3,0-20.3l15,6.5c0,0,6.8,7.3,11.3,7.8c5.1,0.5,10.1,1.3,15,2.5c6.3,1.5,13.8,5.5,16.5,3.5s2.3-4,6.8-4.3
                			s7.3,1.5,10.5-3.8s1.5-7.5,10.5-7.3s17.3-2,18.3,3.8s-3.8,15.8-3.8,15.8l2.8,4.8l7.3-3.3c5-5.1,8.7-11.2,11-18l5-1.5l4.5,2.5h5.8
                			c0,0,4.8-5,8.8-3.8s9,2.3,4,9s-16.3,16-18,18.5s-8,5-8.8,8.5c-0.4,2.3-0.4,4.7,0,7l-7,7.5c0,0-4.3-1-5.8,0
                			c-1.1,0.9-1.8,2.1-2.3,3.5l-8,2.3l-2.5,4.3c0,0,10.8,3.5,10.5,13.8c-0.3,6.7-1.5,13.4-3.5,19.8l4.1,1.2h3.5l3.2,2
                			c0,0,8.7-6.7,16.3-25.5s8.5-17.3,9.5-19.8s-0.2-6.8-0.2-6.8l3-4.2c0,0,3.5,0.3,3.5,0.8s-0.3,5-0.3,5l-1.3,5.2l1.8,0.3l2-3.7h2.3
                			l-0.8,4.4l0.8,4l6.1-4.3l1.8-5.8l2.8,0.7l0.5,3.8l-3.2,4.7l-2,5.3h3.5l4-7.8l1.8,3l-2.2,4.8l3.8,1l5.2-7.7l4.7-0.5v-7.2l1.9-5
                			c0,0-1.6-5.8,0-8.2c1.2-1.5,2.8-2.7,4.6-3.3c1.2,1.7,1.8,3.6,1.9,5.7v5l-1.9,3.8l1.9,3.6h14.2c0,0,4.5-8.1,8.5-11.6
                			s8.2-8.1,8.2-8.1l2.8,4.9l1,7l5.7,2.2l4.3,4.7c0,0-4.5,8.3-5.7,12.5s-2,7-5.7,9.3l1.8,4.2l1.5,29.7l6.2,9l1.8,5l5.2,1.5l2.7-2.2
                			l2.8,4.3c0,0-0.7,6,1.3,10h9.3l2.7-2.7c0,0-1-5.7,0-5.7s8.3-4.2,8.3-4.2s4.5-8.7,5-8.3s3.7,2,3.7,2l1.3,15.3l28,0.2
                			c0,0,9.7-7,14.8-7.7s10.7-1,10.7-1c0.5,2.3,1.2,5.8,1.9,11C561.5,507.3,556.8,511,553.7,512.8z" data-original-title="Nurobod" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[6][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/narpay-tumani">
										<path id="narpay" href="#narpay-data" data-toggle="tab" className="st0" title="" d="M367,359.8l-10,8l-5,1.5c-2.3,6.8-6,12.9-11,18l-7.3,3.3l-2.8-4.8c0,0,4.8-10,3.8-15.8s-9.3-3.5-18.3-3.8
                			s-7.3,2-10.5,7.3s-6,3.5-10.5,3.8s-4,2.3-6.8,4.3s-10.3-2-16.5-3.5c-4.9-1.2-9.9-2-15-2.5c-4.5-0.5-11.3-7.8-11.3-7.8
                			c0-4.3-5.5-7.5-5.5-10.3s3.5-3.8,6-8.5s-0.3-14,0-18.1s7.5-15.6,7.5-15.6l2.8,5.8l8.8,4.1l11-5.5l9.8,3.5l12.1,0.4
                			c3.1,2.1,6.7,3.2,10.4,3.3c6.5,0.2,19.3,2.2,25.5,11.5c3.4,1.6,7.2,2.3,11,2c6.5-0.5,22.8,0.5,23.5,5.5S367,359.8,367,359.8z" data-original-title="Narpay" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[3][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/kattaqorgon-tumani">
										<path id="kattqurgon" href="#kattqurgon-data" data-toggle="tab" title="" className="st0" d="M450.7,377.2c-4,2-6.3,1.1-6.3,1.1s-2.8,2.4-2.2,2.9s3.8,6.2,3.8,6.2l0.3,4.5l1.9,3.4c0,0-4.2,4.6-8.2,8.1
                			s-8.5,11.7-8.5,11.7h-14.2l-1.9-3.6l1.9-3.8v-5c0-2-0.7-4-1.9-5.7c-1.8,0.7-3.4,1.8-4.6,3.3c-1.6,2.3,0,8.2,0,8.2l-1.9,5v7.2
                			l-4.7,0.5l-5.2,7.7l-3.8-1l2.2-4.8l-1.8-3l-4,7.8h-3.5l2-5.3l3.2-4.7l-0.5-3.8l-2.8-0.7l-1.8,5.8l-6.1,4.3l-0.8-4l0.8-4.4h-2.3
                			l-2,3.7l-1.8-0.3l1.3-5.2c0,0,0.3-4.5,0.3-5s-3.5-0.8-3.5-0.8l-3,4.2c0,0,1.2,4.3,0.2,6.8s-1.8,1-9.5,19.8s-16.3,25.5-16.3,25.5
                			l-3.2-2h-3.5l-4.1-1.2c2-6.4,3.2-13,3.5-19.8c0.3-10.3-10.5-13.8-10.5-13.8l2.5-4.3l8-2.3c0.4-1.4,1.2-2.6,2.3-3.5
                			c1.5-1,5.8,0,5.8,0l7-7.5c-0.4-2.3-0.4-4.7,0-7c0.8-3.5,7-6,8.8-8.5s13-11.8,18-18.5s0-7.8-4-9s-8.8,3.8-8.8,3.8h-5.8l-4.5-2.5
                			l10-8c0,0,2.5-9,1.8-14s-17-6-23.5-5.5c-3.8,0.3-7.6-0.4-11-2c-6.3-9.3-19-11.3-25.5-11.5c-3.7,0-7.3-1.2-10.4-3.3
                			c0,0,5.9-5.4,8.7-6.3c8.3-3.8,26.6-3.8,26.6-3.8l2.1-7.5l9.5-4.1c0,0,10.7,0.2,10.9-0.1s4.1-7,4.1-7l-4.3-10.4
                			c0,0,7.6-6.6,8.1-8.3s-0.6-4.5,1.1-6s5.6-5.7,6-10.6l-6.4-2.7l-1.3,7c0,0-4.3,3.9-9.5,4.1l-6.8-7.8l0.1-8.9c0,0,3.8-14.1,3.8-14.5
                			s10.6-14.1,13.2-19.8c2.2-4.3,4.7-8.5,7.5-12.4c2.2,1.1,4.6,1.6,7,1.7c4.7,0.3,9.3-0.2,12.5,0.8s9.2,7.7,9.2,7.7s3,0.6,2.2-5.1
                			c0,0,4.3-2.6,6.8,0s2.5,4.1,5.6,4.4s9,0,12.2,0.7c1.6,0.3,5.1,2,8.7,4l0,0l-1.5,13.7l1.5,1.3v2.7l-3.2,1.2c0,0-1,5.3,0,8.3
                			s0.9,7.7,1.4,8.5s2.5,3.8,5.5,4s6.8-0.5,7.7,2.5s-2.5,7.5-2.5,7.5l0.2,10.8c-1.2,2.1-3,3.9-5.1,5.2c0,0-3.3,7.8-7.5,10.7
                			s-7,3.3-8.7,7.3c-1.1,2.9-1.7,5.9-1.8,9h-8.7l4.2,12l0.7,7.4l4.2,3.6c0,0-3.2,10.5-1.8,19.5c0,0,7.5-0.2,10.7,1.3s16.5,11,16.5,11
                			s6.5,1,6.7,1.5S454.7,375.2,450.7,377.2z" data-original-title="Kattqurgon" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[5][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/qoshrabot-tumani">
										<path id="qushrabot" title="" href="#qushrabot-data" data-toggle="tab" className="st0" d="M553.6,214.3l5.5,5.3v5.7l-1.2,3.3h-8.3l-1.4,6.5l-20.6-0.8l-5.3,4.7v3.7l-4.7,2.2c0,0,2.5,16.5,10,15.7
                			l1.5-7.2l6.5,2.2l5.2,8.2l-1.8,8.8c0,0-6.3,4.3-11.5,4.8s-10.5-4.2-10.5-4.2s-0.8-0.7-5.3,0s-6.8,10.3-9.2,13.7s-9.8,10.7-14.5,13
                			s-20.7-2.8-21.5-3s-2-3.8-2.5-3.8s3.8-7.7,4-8.3s10-9.2,10-9.2c0.5-2.2,0.5-4.6,0-6.8c-0.7-2.7-1.9-5.2-3.7-7.3c0,0-0.2-8,0-10.5
                			s4-2.3,1.7-8c-1.3-4.2-11-1.8-11-1.8s-4.7-2.5-7.2-6s-8.7-8.8-12.5-11.2c-1.9-1.1-5.4-3.2-8.8-5l0,0c-3.6-1.9-7.1-3.6-8.7-4
                			c-3.2-0.6-9-0.4-12.2-0.7s-3.1-1.8-5.6-4.4s-6.8,0-6.8,0c0.8,5.7-2.2,5.1-2.2,5.1s-6-6.7-9.2-7.7s-7.8-0.5-12.5-0.8
                			c-2.4-0.1-4.8-0.7-7-1.7l-0.1-7l-3.3-3.1l6.6-4.6l3.2-16l-4-3.9l4.3-12.3l-4.3-3.9l1.4-10.6l7.3-0.8l2.9-5.4l-4.4-3.4l0.6-17.5
                			l3.7-4.8l-2.7-4.4l2.2-15.7l6.9-5.3l10.4-15c0,0-2.1-24.4-1.8-24.6s16.5,7.9,16.9,7.8s29.3,2.4,29.3,2.4l-1.4,20.9l3.9-0.3
                			l3.8,5.3l17.1,3.1l11.4,15.6l13.5,1.4l0.1,4.1l-7.1,1.8c0,0-1.5,3.9-0.4,6.1s7.3,5.4,7.3,5.4l6.5-0.5l12.4,3.8l-7,17.1l3.8,4.9
                			c0,0,7.9,0.1,8.6-1s5.5-7.8,10.8-6.6s7.5,8.9,7.5,8.9l10.2,1.1c0,0,3.2,8.7,5.2,13s5.7,8.7,5.7,9.2s0.2,20,0,20.7
                			s-7.2,19.2-6.7,19.2S553.6,214.3,553.6,214.3z" data-original-title="Qoshrabot" fill="transparent" stroke="#fff" strokeWidth="2">

										</path>
									</Link>
								</Popover></g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[9][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/payariq-tumani">
										<path id="payariq" href="#payariq-data" data-toggle="tab" className="st0" title="" d="M609.3,356.8l-2.8,3.7l2.8,9.2l17.8,11.2l1,4.2l-9.5,6.7h-6.5l-3-2.3l-4.3,0.2l-0.2,6.8l-3.3,2.7v4l7,7.2v6.8
                			l-8-3c0,0-12.3-8.3-12.8-8.5s-11.8-7.8-11.8-7.8s-23.3-31.5-25.7-35.3s-10.7-5.5-10.7-5.5c-2.7-6.2-7.2-4.8-10.5-5.5
                			s-5.7-4.2-7.7-7s-7.2-6-8.3-8.8s0-14,0-14s5.7-3.3,8.8-6.8s2.3-11.3,4-12.7s9.5-1.6,9.5-1.6l3-3.9l6,0.3c0,0,5.8,3,9.8,2.8
                			s6.3-3.2,7.8-5.8s-2.8-8.3-2.8-8.3s0.6-5.3-2.1-6.2s-7.5,8.2-11.3,3.3c-0.7-4.3,2.5-6,3.2-10.2c0.5-2.3-0.5-4.6-2.5-5.8H544
                			l-3.3-3l-5.2-8.2l-6.5-2.2l-1.5,7.2c-7.5,0.8-10-15.7-10-15.7l4.7-2.2V239l5.3-4.7l20.6,0.8l1.4-6.5h8.3l1.2-3.3v-5.7l-5.5-5.3
                			c0,0-0.8-4.7-1.3-4.7s6.5-18.5,6.7-19.2s0-20.2,0-20.7s-3.7-4.8-5.7-9.2s-5.2-13-5.2-13s17.5-6.9,34-5l-2.6,8c0,0,6.1,9.8,6,11.6
                			s-3.5,8.3-3.5,8.3c0.3,6.1,0.2,12.2-0.1,18.3c-0.6,7.4-1.5,15.1-2.4,18.6s-4.3,12.9-4.5,15.2c-0.2,1.7-0.2,3.4-0.1,5.1
                			c0,0,10.9,5,14.2,5.9c0,0,11,9,11.6,9s-2.4,6.5-2.4,6.5l3.6,2.8c0,0,5.6-3.1,8.5-3.3c0,0,1.8,6.1-1.4,9.9s-10.5,11-12.2,11.3
                			s-7.3,2-7.3,2s1.6,7.1,4.3,7.6c2.4,0.3,4.9,0.3,7.4,0l7.9,1.7c0,0,11,12.9,15.9,15.1l2.4,8.9v13.3c0,0-8.8,7.1-8.3,7.4
                			s6.8,10.1,6.8,10.1l5.1-2.4v11.8l-4.4,5l-5.5,1.5l-2.7,3.2v1.8H609.3z" data-original-title="Payariq" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[1][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/jomboy-tumani">
										<path id="jomboy" href="#jomboy-data" data-toggle="tab" className="st0" title="" d="M667.8,434.3c-0.3,3.9,7.4,13.4,7.8,14l-0.5,11.7l5.8,8.3l-3.2,7.7v5.8c-8.1-4.1-15.8-9-23-14.6
                			c0,0-7.5,0.5-10.3-3.2s-4.3-7.2-9.3-6.3c0,0-3.8-7-4.2-7.5s-3.3-1-5.3-3.7c-2-3-3.7-6-5.3-9.2l-4.2-8.2l-7.8-12.2v-6.8l-7-7.2v-4
                			l3.3-2.7l0.2-6.8l4.3-0.2l3,2.3h6.5l9.5-6.7l-1-4.2l-17.8-11.2l-2.8-9.2l2.8-3.7h9V355l2.7-3.2l5.5-1.5l4.4-5v-11.8l5.6-2.7
                			c0,0,2.8,17.1,2.6,17.5s5.1,4.9,5.1,4.9s9.8,2.1,14.8-2l3.1,4.5h4V351l8.6,0.3l1.9,7.1l6.9,2.5l-2.8,4.3c0,0-10.5,3.3-12.5,8.7
                			s-1.3,11.8-1.3,12.3s3.5,2.3,3.5,4.7s-5.2,6-4.3,13.3l16.5,12.8l7.2,6.2v3.5l-10.3,0.8l-4,3.7
                			C675.3,431.2,668.1,430.5,667.8,434.3z" data-original-title="Jomboy" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[13][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/samarqand-shahri">
										<path id="samarqandt" href="#samarqandt-data" data-toggle="tab" className="st0" title="" d="M635.2,457.7l-0.7,4.5c0,0-7.2-0.3-7.2,0s-0.2,3.3-0.2,3.3l-2.8,3.7l1.7,11.5l-2,4.5h-5c0,0-3.8-1.7-5.2,0
                			s5.3,5.5,5.3,5.5l2,4.8l-2.9,1.8c0,0,4.4,8.5,1.1,11.7c-3.3,3.2-11.2-1.3-11,3.5c0.2,4.8,5.5,4,4.5,8.7s-2.2,8.2-8.5,9.5
                			s-7.8,0-9.8,2.5s-4.7,6-4.7,6l-3.5-1.2c0,0-1.2-4.7-5.3-4.7s-4.2,3.3-6.2,4.2s-9.8-1.7-10.3,2.7s3.8,8.1,3.8,8.1
                			c-3.2,0.7-6.6,1-9.9,1C554.5,549,546,548,546,548l-5.1-6.3l-0.8-5l-6-0.5c0.2-1.9,1.5-3.5,3.4-4.1c3.2-1,5,0.5,5.7-2.2
                			s0.5-4.5,2.7-5.5s4,0,3.7-4.2s1-5.7,4.2-7.5s7.8-5.5,6.2-16.5c-0.8-5.1-1.4-8.7-1.9-11c-0.6-2.6-0.9-3.7-0.9-3.7l-2.8-3.3
                			l-0.2-8.7c0,0,7.7-7.8,14-7.8c0,0,4.3-5.5,8-7.8c2-1.3,4-2.7,5.8-4.2l3-6c0,0-3.3-10.3-7-10.8s-9.5,0.5-9.5,0s-1.5-5-1.5-5
                			l-2.7-2.5l-1.3-4.7l0.3-4.3l-17-7.5l-0.7-4.7h5.8c0.6-0.5,1.3-0.9,2-1.3c0.5-0.2,3.8,1.3,3.8,1.3l5-0.7l4.2,2l3.2,5.2l3.2,2.2
                			l1.2,7.3c0,0,9.5,2.3,10,2.7s6.8,5.3,6.8,5.3h6.3l12.2,7.2l11.2,1.8c1.6,3.2,3.4,6.2,5.3,9.2c2,2.7,4.9,3.2,5.3,3.7
                			S635.2,457.7,635.2,457.7z" data-original-title="Samarqand tumani" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[0][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/bulungur-tumani">
										<path id="bulungur" href="#bulungur-data" data-toggle="tab" title="" className="st0" d="M771.5,397.3l-1.4,3.9l-11.6-0.9l-4.8,3.9l-1.1,8.8c0,0-13.4,9.4-17.7,21.9l0.4,22.6h5.1l0.9,3.5
                			c0,0,6-0.5,6.4,0s6.5,4.7,6.5,4.7s-1.1,2.6-0.4,7.3c0.5,3.4,0.7,6.9,0.6,10.4l-2.4,3.5c-2.8,0.9-13.6-4.6-16.9-3.8l-3,6.4
                			l-0.1,9.2l-6.9-3.8l-2.9-1.6l-17-0.3c-9.4-2.8-18.6-6.5-27.4-11.1V476l3.2-7.7l-5.8-8.3l0.5-11.7c-0.5-0.6-8.1-10.1-7.8-14
                			s7.5-3.2,7.5-3.2l4-3.7l10.3-0.8v-3.5l-7.2-6.2L666,404.2c-0.8-7.3,4.3-11,4.3-13.3s-3.5-4.2-3.5-4.7s-0.7-7,1.3-12.3
                			s12.5-8.7,12.5-8.7l2.8-4.3l5.8-0.8l3.7,1.4l10.8,0.8l4.5-4.8l9.5,0.3l5.8,2.1l6.3,0.1l3.1-2.6l3.9-1l2,2.9l-2.9,5.6
                			c0,0,11.6,10.4,12,10.4s7.2,2.6,7.2,2.6l2.1,7.6C763,388,767.9,392.1,771.5,397.3z" data-original-title="Bulungur" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[15][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/urgut-tumani">
										<path id="urgut" href="#urgut-data" data-toggle="tab" className="st0" title="" d="M762,575.5l-1.4,3.4l-10.5-2.1c-3.9,0.7-7.7,2-11.4,3.6c-5,2.5-17.3,6.7-17.3,6.7l-1.8,2.8
                			c0,0-10.9,4.5-16.4,4.1s-26.3-4.8-33.9-7.4l-1,3.3l-4.8,3.2c0,0-3.8-5.6-3.8-6s0.1-3.7,0.1-3.7l-6.2-4.8l-25.8-0.2l-5.1,1.4
                			l-0.3,7.1l3,9.6l-13.3,17.4l-5.4,1.4l0.1-3.1l-3.1-6c0,0-13.1,3.3-17,3s-9.9-1.2-9.9-1.2l-3,4l-7.3-2.8v-5.2l2.1-4.1l2.4,0.3
                			c0,0-0.5-8,1.8-13.6c0,0-4.8-8.5-4.1-14.5s2.1-12.8,2.1-12.8l-3-10.9c0,0-4.3-3.8-3.8-8.1s8.3-1.8,10.3-2.7s2-4.2,6.2-4.2
                			s5.3,4.7,5.3,4.7l3.5,1.2c0,0,2.7-3.5,4.7-6s3.5-1.2,9.8-2.5s7.5-4.8,8.5-9.5s-4.3-3.8-4.5-8.7s7.7-0.3,11-3.5s-1.1-11.7-1.1-11.7
                			l2.9-1.8c0,0,2.3,4.7,2.8,4.3s11.2-5.8,20-2.5c0,0,11,15.8,20,17s11.8,1,11.8,1l6.8-8.7l16.7,0.5l3.5-5l10.8,0.8
                			c2.5-1.6,5.5-2.6,8.5-2.7l2.9-5.5l6.9,3.8c0,0,14.6,32.5,16.8,39S762,575.5,762,575.5z" data-original-title="Urgut" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[2][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/ishtixon-tumani">
										<path id="ishtixon" href="#ishtixon-data" data-toggle="tab" className="st0" title="" d="M561.8,293.8c-1.5,2.7-3.8,5.7-7.8,5.8s-9.8-2.8-9.8-2.8l-6-0.3l-3,3.9c0,0-7.8,0.2-9.5,1.6s-0.8,9.2-4,12.7
                			s-8.8,6.8-8.8,6.8s-1.2,11.2,0,14c1.2,2.8,6.3,6,8.3,8.8s4.3,6.3,7.7,7s7.8-0.7,10.5,5.5h-8.8l-14-8c0,0-6.2-6.2-9.8-6
                			c-2.5,0.2-4.8,1.1-6.8,2.5c0,0-8-3-8.8-1.7s-0.5,3.2,0.8,7.3s5.3,7.3,4.5,18.7s-5.1,18.5-5.1,18.5l-11.7-5.3l-5.7-5.2l-12.5-7.7
                			l-6.8-2.2c-0.2-0.5-6.7-1.5-6.7-1.5s-13.3-9.5-16.5-11s-10.7-1.3-10.7-1.3c-1.3-9,1.8-19.5,1.8-19.5l-4.2-3.6l-0.7-7.4l-4.2-12
                			h8.7c0.1-3.1,0.8-6.1,1.8-9c1.7-4,4.5-4.5,8.7-7.3s7.5-10.7,7.5-10.7c2.1-1.3,3.8-3.1,5.1-5.2l-0.2-10.8c0,0,3.3-4.5,2.5-7.5
                			s-4.7-2.3-7.7-2.5s-5-3.2-5.5-4s-0.4-5.5-1.4-8.5s0-8.3,0-8.3l3.2-1.2v-2.7l-1.5-1.3l1.5-13.7c3.4,1.8,6.9,3.9,8.8,5
                			c3.8,2.3,10,7.7,12.5,11.2s7.2,6,7.2,6s9.7-2.3,11,1.8c2.3,5.7-1.5,5.5-1.7,8s0,10.5,0,10.5c1.7,2.2,3,4.7,3.7,7.3
                			c0.5,2.2,0.5,4.6,0,6.8c0,0-9.8,8.5-10,9.2s-4.5,8.3-4,8.3s1.7,3.7,2.5,3.8s16.8,5.3,21.5,3s12.2-9.7,14.5-13s4.7-13,9.2-13.7
                			s5.3,0,5.3,0s5.3,4.7,10.5,4.2s11.5-4.8,11.5-4.8l1.8-8.8l3.3,3h2.3c2,1.2,3,3.6,2.5,5.8c-0.7,4.2-3.8,5.8-3.2,10.2
                			c3.8,4.8,8.7-4.2,11.3-3.3s2.1,6.2,2.1,6.2S563.3,291.2,561.8,293.8z" data-original-title="Ishtixon" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[10][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/pastdargom-tumani">
										<path id="pastdargom" href="#pastdargom-data" data-toggle="tab" className="st0" title="" d="M584.8,443.8l-3,6c-1.9,1.5-3.8,2.9-5.8,4.2c-3.7,2.3-8,7.8-8,7.8c-6.3,0-14,7.8-14,7.8l0.2,8.7l2.8,3.3
                			c0,0,0.3,1,0.9,3.7c0,0-5.6,0.3-10.7,1s-14.8,7.6-14.8,7.6l-28-0.2l-1.3-15.3c0,0-3.2-1.7-3.7-2s-5,8.3-5,8.3S487,489,486,489
                			s0,5.7,0,5.7l-2.7,2.7H474c-2-4-1.3-10-1.3-10l-2.8-4.3l-2.7,2.2l-5.2-1.5l-1.8-5l-6.2-9l-1.5-29.7l-1.8-4.2
                			c3.7-2.3,4.5-5.2,5.7-9.3S462,414,462,414l-4.3-4.7l-5.7-2.2l-1-7l-2.8-4.9l-1.9-3.4l-0.3-4.5c0,0-3.2-5.7-3.8-6.2
                			s2.2-2.9,2.2-2.9s2.3,0.9,6.3-1.1s4-8.8,3.8-9.3l6.8,2.2l12.5,7.7l5.7,5.2l11.7,5.3l17.5,7.9l20.2,4.3l16.7,4l0.7,4.7l17,7.5
                			l-0.3,4.3l1.3,4.7l2.7,2.5c0,0,1.5,4.5,1.5,5s5.8-0.5,9.5,0S584.8,443.8,584.8,443.8z" data-original-title="Pastdargom" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[8][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/oqdaryo-tumani">
										<path id="oqdaryo" href="#oqdaryo-data" data-toggle="tab" className="st0" title="" d="M620.3,437.3l-11.2-1.8l-12.2-7.2h-6.3c0,0-6.3-5-6.8-5.3s-10-2.7-10-2.7l-1.2-7.3l-3.2-2.2l-3.2-5.2l-4.2-2
                			l-5,0.7c0,0-3.3-1.5-3.8-1.3c-0.7,0.4-1.4,0.8-2,1.3h-5.8l-16.7-4l-20.2-4.3l-17.5-7.9c0,0,4.3-7.1,5.1-18.5s-3.2-14.5-4.5-18.7
                			s-1.7-6-0.8-7.3s8.8,1.7,8.8,1.7c2-1.4,4.4-2.3,6.8-2.5c3.7-0.2,9.8,6,9.8,6l14,8h8.8c0,0,8.3,1.7,10.7,5.5s25.7,35.3,25.7,35.3
                			s11.3,7.7,11.8,7.8s12.8,8.5,12.8,8.5l8,3l7.8,12.2L620.3,437.3z" data-original-title="Oqdaryo" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							<g className="grouped">
								<Popover title={zones[7]?.districts[14][`name_${lang}`]}>
									<Link to="/samarqand-viloyati/tayloq-tumani">
										<path id="tayloq" href="#tayloq-data" data-toggle="tab" className="st0" title="" d="M725.1,494.8l-2.9,5.5c-3,0.1-6,1-8.5,2.7l-10.8-0.8l-3.5,5l-16.7-0.5l-6.8,8.7c0,0-2.8,0.2-11.8-1
                			s-20-17-20-17c-8.8-3.3-19.5,2.2-20,2.5s-2.8-4.3-2.8-4.3l-2-4.8c0,0-6.7-3.8-5.3-5.5s5.2,0,5.2,0h5l2-4.5l-1.7-11.5l2.8-3.7
                			c0,0,0.2-3,0.2-3.3s7.2,0,7.2,0l0.7-4.5c5-0.8,6.5,2.7,9.3,6.3s10.3,3.2,10.3,3.2c7.2,5.6,14.9,10.5,23,14.6
                			c8.8,4.5,17.9,8.2,27.4,11.1l17,0.3L725.1,494.8z" data-original-title="Tayloq" fill="transparent" stroke="#fff" strokeWidth="2"></path>
										<path className="st2" d="M675.7,448.3L675.7,448.3" fill="transparent" stroke="#fff" strokeWidth="2"></path>
									</Link>
								</Popover>
							</g>
							{/* <circle id="kattaqorgonsh" title="" href="#kattaqorgonsh-data" data-toggle="tab" className="st0" cx="394.5" cy="362.4" r="9.5" data-original-title="Kattaqorgon city"></circle>
                <circle id="samarqandsh" title="" href="#samarqandsh-data" data-toggle="tab" className="st0" cx="607.6" cy="456.6" r="12.6" data-original-title="Samarkand city"></circle> */}
						</svg>
					</div >
					:
					<LoadingPage />
			}
		</>
	)
}

export default SamarqandMap