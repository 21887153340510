import MyLayout from "./components/layout/MyLayout";
import { Route, Routes } from "react-router-dom";
// import CalculatorRoute from "./components/mapscontainer/CalculatorRoute";
import { useHospital } from "./context/Context";
import ErrorPage from "./pages/ErrorPage";
import LoadingPage from "./pages/LoadingPage";

function App() {
  const {
    thisRouteRegion,
    thisRouteDistrict,
    isToken,
    isLoading
  } = useHospital()
  return (
    <>
      {!isLoading && isToken
        ?
        <div className="App">
          <Routes>
            <Route path='/' element={<MyLayout />} />
            {/* <Route path='/organization-route' element={<CalculatorRoute />} /> */}
            <Route path="/no-data" element={<ErrorPage />} />
            <Route path={`/${thisRouteRegion}`} element={<MyLayout />} />
            <Route path={`/${thisRouteDistrict}`} element={<MyLayout />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        :
        <LoadingPage />
      }
    </>
  );
}

export default App;
